import { validSectionText } from "@saffron/common";
import {
  bulkMoveRecipeSectionsMutationOptions,
  CookbookAccessLevel,
  CookbooksQuery,
  deleteSectionMutationOptions,
  JustSectionFragment,
  moveSectionToCookbookMutationOptions,
  RecipeAccessLevel,
  RecipeNameFragment,
  renameSectionMutationOptions,
  useBulkMoveRecipeSectionsMutation,
  useDeleteSectionMutation,
  useMoveSectionToCookbookMutation,
  useRenameSectionMutation
} from "@saffron/controllers";
import React, { useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useDrop } from "react-dnd";
import TooltipTrigger from "react-popper-tooltip";
import styled from "styled-components";
import Icon from "../../../../ui/Icon";
import { LineModalForm } from "../../../../ui/LineModalForm";
import { ListButton } from "../../../../ui/ListButton";
import { Modal } from "../../../../ui/Modal";
import { theme } from "../../../../ui/theme";
import { ItemTypes } from "../../../menuPlanner/shared/ItemTypes";
import { notificationState } from "../../../misc/notifications/NotificationState";
import { PickCookbookModal } from "../../../shared/PickCookbookModal";

interface Props {
  section: JustSectionFragment;
  onSectionClick: (id: string) => any;
  cookbook: CookbooksQuery["cookbooks"][0];
  selected?: boolean;
  enableDrag?: boolean;
  index: number;
  onDelete?: () => void;
  sectionAccessLevel: RecipeAccessLevel;
}

const Item = styled("div")<{ selected?: boolean; isOver?: boolean }>`
  line-height: 18.9px;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  ${p =>
    p.selected
      ? `
            background-color: ${theme.selectedColor};
            color: #fff;
          `
      : `
          &:hover {
            background-color: ${theme.hoverColor};
            color: #0d0d0d;
          }
            `};
  ${p =>
    p.isOver
      ? `
  background-color: #F6F3E1;
            color: #0d0d0d;
  `
      : ``}
`;

export const SectionItem: React.FC<Props> = ({
  section,
  selected,
  cookbook,
  onSectionClick,
  onDelete,
  enableDrag,
  sectionAccessLevel,
  index
}) => {
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [moveRecipes] = useBulkMoveRecipeSectionsMutation();
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.RECIPE_IN_SECTION,
    drop: ({ recipe, sectionId: currentSectionId }: any) => {
      moveRecipes(
        bulkMoveRecipeSectionsMutationOptions(
          {
            options: {
              recipeIds: [recipe.id],
              targetSectionId: section.id,
              sectionIdToRemove: currentSectionId
            }
          },
          currentSectionId,
          [recipe as RecipeNameFragment],
          {
            __typename: "CookbookSectionLookup",
            cookbook,
            section
          }
        )
      ).then(() => {
        notificationState.send({
          text: "successfully moved",
          variant: "success"
        });
      });
    },
    collect: mon => ({
      isOver: !!mon.isOver()
    })
  });
  const [renameSection] = useRenameSectionMutation();
  const [
    moveSectionToCookbook,
    { loading: moveSectionToCookbookLoading }
  ] = useMoveSectionToCookbookMutation();
  const [
    deleteSection,
    { loading: deleteSectionLoading }
  ] = useDeleteSectionMutation();
  const tooltip = useRef<any>();

  return (
    <>
      <Modal
        isOpen={deleteModalOpen}
        onRequestClose={() => setDeleteModalOpen(false)}
        title="Permanently Delete Section"
        mainButtonText="DELETE"
        buttonVariant="red"
        secondaryButtonText="CANCEL"
        secondaryButtonClick={() => setDeleteModalOpen(false)}
        loading={deleteSectionLoading}
        mainButtonClick={async () => {
          await deleteSection(
            deleteSectionMutationOptions({ id: section.id }, cookbook.id)
          );
          setDeleteModalOpen(false);
          if (onDelete) {
            onDelete();
          }
        }}
      >
        <div>
          Deleting a section cannot be undone and will permanently delete any of
          the recipes inside of it.
        </div>
      </Modal>
      <LineModalForm
        open={renameModalOpen}
        label="section name"
        title="Rename Section"
        submit={async text => {
          await renameSection(
            renameSectionMutationOptions({
              __typename: "Section",
              id: section.id,
              owner: section.owner,
              position: section.position,
              name: text
            })
          );
        }}
        initialText={section.name}
        closeModal={() => setRenameModalOpen(false)}
        validationSchema={validSectionText}
      />
      <Draggable
        isDragDisabled={
          !enableDrag || sectionAccessLevel === CookbookAccessLevel.Viewer
        }
        draggableId={section.id}
        index={index!}
      >
        {(dragProvided, { isDragging }) => {
          return (
            <Item
              ref={x => {
                dragProvided.innerRef(x);
                drop(x);
              }}
              {...dragProvided.draggableProps}
              {...dragProvided.dragHandleProps}
              selected={selected || isDragging || isOver}
              isOver={isOver}
              onClick={() => onSectionClick(section.id)}
            >
              <div
                style={{
                  flex: 1,
                  padding: "10px 1.4em",
                  paddingRight: 4
                }}
              >
                {section.name}
              </div>
              {selected && sectionAccessLevel !== CookbookAccessLevel.Viewer ? (
                <TooltipTrigger
                  ref={tooltip}
                  placement="bottom"
                  trigger="click"
                  tooltip={({ tooltipRef, getTooltipProps }) => {
                    return (
                      <div
                        {...getTooltipProps({
                          ref: tooltipRef,
                          className: "tooltip-container",
                          style: {
                            width: 185,
                            paddingBottom: 5,
                            paddingTop: 5,
                            borderRadius: 4,
                            backgroundColor: "#fff",
                            boxShadow: "0 3px 12px rgba(27,31,35,.15)",
                            border: "1px solid rgba(27,31,35,.15)"
                          }
                        })}
                      >
                        <ListButton
                          onClick={() => {
                            setRenameModalOpen(true);
                            if (tooltip.current) {
                              tooltip.current.hideTooltip();
                            }
                          }}
                        >
                          Rename
                        </ListButton>
                        {sectionAccessLevel === CookbookAccessLevel.Owner ? (
                          <ListButton
                            onClick={() => {
                              setMoveModalOpen(true);
                              if (tooltip.current) {
                                tooltip.current.hideTooltip();
                              }
                            }}
                          >
                            Move
                          </ListButton>
                        ) : null}
                        <ListButton
                          onClick={() => {
                            setDeleteModalOpen(true);
                            if (tooltip.current) {
                              tooltip.current.hideTooltip();
                            }
                          }}
                        >
                          Delete
                        </ListButton>
                      </div>
                    );
                  }}
                >
                  {({ getTriggerProps, triggerRef }) => (
                    <div>
                      <div
                        {...getTriggerProps({
                          ref: triggerRef,
                          className: "trigger",
                          style: {
                            marginTop: 2.5,
                            padding: 10,
                            cursor: "pointer"
                          }
                        })}
                      >
                        <Icon
                          fill="rgba(255, 255, 255, 0.5)"
                          height="1em"
                          width="1em"
                          name="dots"
                        />
                      </div>
                    </div>
                  )}
                </TooltipTrigger>
              ) : null}
            </Item>
          );
        }}
      </Draggable>
      <PickCookbookModal
        buttonText="MOVE"
        cookbookId={cookbook.id}
        onRequestClosed={() => setMoveModalOpen(false)}
        open={moveModalOpen}
        title="Choose Cookbook:"
        onSubmit={async cb => {
          if (!cb) {
            return;
          }

          await moveSectionToCookbook(
            moveSectionToCookbookMutationOptions(
              {
                input: { cookbookId: cb.id, sectionId: section.id }
              },
              section,
              cookbook.id
            )
          );
          notificationState.send({
            text: "successfully moved to " + cb.name,
            variant: "success"
          });
        }}
        loading={moveSectionToCookbookLoading}
      />
    </>
  );
};
