import { FormikProps } from "formik";
import React, { useCallback, useMemo, useRef } from "react";
import { createEditor, Node, Range } from "slate";
import { Editable, RenderElementProps, Slate, withReact } from "slate-react";
import { theme } from "../../../ui/theme";
import { EditorWrapper } from "../../recipe/shared/RecipeForm/ui/EditorComponents";
import {
  insertText,
  removeBlockOnReturn,
  toggleBlock,
  withPasteBlocks
} from "../slateUtils";
import { withHistory } from "slate-history";

interface Props {
  renderHeader: (data: any) => JSX.Element | null;
  field: {
    onChange: (e: React.ChangeEvent<any>) => void;
    onBlur: (e: any) => void;
    value: Node[];
    name: string;
  };
  placeholder?: string;
  form: FormikProps<any>;
}

export const InstructionsDraftField: React.FC<Props> = ({
  field: { name, value },
  form: { setFieldValue },
  renderHeader,
  placeholder,
  ...props
}) => {
  const editor = useMemo(
    () => withHistory(withPasteBlocks(withReact(createEditor()))),
    []
  );
  const selection = useRef<Range | null>(null);

  if (editor.selection) {
    selection.current = editor.selection;
  }

  const renderElement = useCallback(
    ({ attributes, children, element: { type } }: RenderElementProps) => {
      switch (type) {
        case "header-four":
          return (
            <h4
              style={{
                fontFamily: theme.fonts.secondary,
                color: "#824028",
                textTransform: "uppercase",
                letterSpacing: "0.0262em",
                fontSize: "1.125rem"
              }}
              className="draftjs-instruction-header"
              {...attributes}
            >
              {children}
            </h4>
          );
        case "optional-text":
          return (
            <div
              style={{
                paddingBottom: "1em",
                fontFamily: theme.fonts.secondary,
                color: "#70A3B7"
              }}
              {...attributes}
            >
              {children}
            </div>
          );
        default:
          // paragraph
          return (
            <div
              style={{
                paddingBottom: "1em",
                fontFamily: theme.fonts.secondary,
                color: "#0d0d0d",
                lineHeight: "1.5625rem"
              }}
              {...attributes}
            >
              {children}
            </div>
          );
      }
    },
    []
  );

  const onKeyDown = useCallback(
    e => {
      removeBlockOnReturn(e, editor);
    },
    [editor]
  );

  return (
    <React.Fragment>
      {renderHeader({
        toggleHeader: (e: any) => {
          e.preventDefault();
          toggleBlock(editor, "header-four");
        },
        insertText: (text: string) =>
          insertText(text, editor, selection.current)
      })}
      <EditorWrapper>
        <Slate
          editor={editor}
          value={value}
          onChange={x => setFieldValue(name, x)}
          {...props}
        >
          <Editable
            onKeyDown={onKeyDown}
            autoCorrect="off" // disable mac text replace, doesn't work well with slate
            placeholder={placeholder}
            autoFocus
            renderElement={renderElement}
          />
        </Slate>
      </EditorWrapper>
    </React.Fragment>
  );
};
