import { useChangeRecipePrivacyMutation } from "@saffron/controllers";
import React from "react";
import { PrivacyModal } from "./PrivacyModal";

interface Props {
  open: boolean;
  toggleModal: () => void;
  privacy: string;
  id: string;
  shareId: string | null | undefined;
  slug: string;
}

const createUrl = (shareId: string, slug: string) =>
  `${window.location.origin.replace(
    "www.",
    "share."
  )}/a/shared/recipe/${shareId}/${slug}`;

export const ShareModal: React.FC<Props> = ({
  open,
  toggleModal,
  privacy,
  id,
  shareId,
  slug
}) => {
  const [changeRecipePrivacy, { loading }] = useChangeRecipePrivacyMutation();
  const unlisted = privacy === "unlisted";
  return (
    <PrivacyModal
      open={open}
      title="Share Recipe"
      description={
        !unlisted
          ? "Your Saffron recipe is private by default. To share this recipe, click on the create link below."
          : "Anyone with the link can view."
      }
      buttonText={unlisted ? "STOP SHARING" : "START SHARING"}
      url={shareId ? createUrl(shareId, slug) : ""}
      showUrl={unlisted}
      toggleModal={toggleModal}
      onButtonClick={async () => {
        await changeRecipePrivacy({
          variables: {
            id,
            unlisted: !unlisted
          }
        });
      }}
      loading={loading}
      embedCode={`<div class="saffron-embed" id="saffron-recipe-embed" data-recipe-id="${id}"></div>
            <script async="true" src="https://widget.mysaffronapp.com/widget.js"></script>
            <link rel="stylesheet" type="text/css" href="https://widget.mysaffronapp.com/widget.css" />`}
    />
  );
};
