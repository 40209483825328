import { RecipePreviewFragment } from "@saffron/controllers";
import * as React from "react";
import Icon from "../../ui/Icon";
import { useReactToPrint } from "react-to-print";
import { PrintableRecipeClass } from "../recipe/PrintableRecipe";
import { Modal } from "../../ui/Modal";

interface Props {
  recipe: RecipePreviewFragment;
}

const initialState = {
  renderPrintComponent: false,
  showImg: true,
  modalOpen: false
};

export const PdfRicon: React.FC<Props> = React.memo(({ recipe }) => {
  const printCompRef = React.useRef<any>();
  const [
    { renderPrintComponent, showImg, modalOpen },
    setRenderPrintComponent
  ] = React.useState(initialState);
  const print = useReactToPrint({
    pageStyle:
      "@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
    onAfterPrint: () => {
      setRenderPrintComponent(initialState);
    },
    content: () => printCompRef.current
  });

  const printRef = React.useRef(() => {});
  printRef.current = () => print?.();

  React.useEffect(() => {
    if (renderPrintComponent) {
      printRef.current();
    }
  }, [renderPrintComponent]);

  return (
    <>
      <Modal
        title="Print/PDF Options"
        isOpen={!!modalOpen}
        onRequestClose={() => setRenderPrintComponent(initialState)}
        mainButtonText="YES"
        mainButtonClick={() => {
          setRenderPrintComponent({
            modalOpen: false,
            renderPrintComponent: true,
            showImg: true
          });
        }}
        secondaryButtonText="NO"
        secondaryButtonClick={() =>
          setRenderPrintComponent({
            modalOpen: false,
            renderPrintComponent: true,
            showImg: false
          })
        }
      >
        Include image for this recipe?
      </Modal>
      <div style={{ display: "none" }}>
        <PrintableRecipeClass
          ref={printCompRef}
          recipe={recipe}
          showImg={showImg}
          show={renderPrintComponent}
        />
      </div>
      <Icon
        tooltipText="print/save as pdf"
        template="recipe"
        onClick={() => {
          if (!recipe.pictureUrl) {
            setRenderPrintComponent({
              modalOpen: false,
              renderPrintComponent: true,
              showImg: false
            });
            return;
          }
          setRenderPrintComponent({
            renderPrintComponent: false,
            showImg: false,
            modalOpen: true
          });
        }}
        name="print"
        arrow
      />
    </>
  );
});
