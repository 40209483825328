import {
  CookbookAccessLevel,
  CookbookSectionLookupInfo,
  MeUserFragment,
  RecipeAccessLevel,
  RecipePreviewFragment,
  updateAfterCloneRecipe,
  useCloneRecipeMutation
} from "@saffron/controllers";
import React, { useRef } from "react";
import TooltipTrigger from "react-popper-tooltip";
import Icon from "../../ui/Icon";
import { ListButton } from "../../ui/ListButton";
import { notificationState } from "../misc/notifications/NotificationState";
import { UpgradeModal } from "../shared/UpgradeModal";

interface Props {
  user: MeUserFragment;
  recipe: RecipePreviewFragment;
  cookbookSectionLookupInfo: CookbookSectionLookupInfo;
  onClone: (r: RecipePreviewFragment) => void;
  recipeAccessLevel: RecipeAccessLevel;
}

export const MoreRicon: React.FC<Props> = ({
  user,
  recipe,
  cookbookSectionLookupInfo,
  recipeAccessLevel,
  onClone
}) => {
  const [cloneRecipe] = useCloneRecipeMutation();
  const tooltip = useRef<TooltipTrigger>(null);
  if (recipeAccessLevel === CookbookAccessLevel.Viewer) {
    return null;
  }
  return (
    <>
      <UpgradeModal title="Recipe Limit Reached">
        {setUpgradeModalOpen => (
          <TooltipTrigger
            ref={tooltip}
            placement="bottom"
            trigger="click"
            tooltip={({ tooltipRef, getTooltipProps }) => {
              return (
                <div
                  {...getTooltipProps({
                    ref: tooltipRef,
                    className: "tooltip-container",
                    style: {
                      zIndex: 1,
                      width: 200,
                      paddingBottom: 5,
                      paddingTop: 5,
                      borderRadius: 4,
                      backgroundColor: "#fff",
                      boxShadow: "0 3px 12px rgba(27,31,35,.15)",
                      border: "1px solid rgba(27,31,35,.15)"
                    }
                  })}
                >
                  <ListButton
                    onClick={() => {
                      (tooltip.current as any)?.hideTooltip();
                      if (user.numRecipes >= user.role.recipeLimit) {
                        setUpgradeModalOpen(true);
                        return;
                      }
                      const variables = {
                        options: {
                          recipeId: recipe.id,
                          sectionId: cookbookSectionLookupInfo.section.id
                        }
                      };
                      notificationState.send({
                        variant: "info",
                        text: "cloning in progress"
                      });
                      cloneRecipe({
                        variables,
                        update: updateAfterCloneRecipe(
                          variables,
                          cookbookSectionLookupInfo.cookbook.id
                        )
                      }).then(data => {
                        const r = data.data?.cloneRecipe.recipe;
                        if (r) {
                          notificationState.send({
                            variant: "success",
                            text: "clone successful"
                          });
                          onClone(r);
                        }
                      });
                    }}
                  >
                    Clone Recipe
                  </ListButton>
                </div>
              );
            }}
          >
            {({ getTriggerProps, triggerRef }) => (
              <div
                {...getTriggerProps({
                  ref: triggerRef,
                  className: "trigger",
                  style: {
                    cursor: "pointer"
                  }
                })}
              >
                <Icon name="dots" template="recipe" />
              </div>
            )}
          </TooltipTrigger>
        )}
      </UpgradeModal>
    </>
  );
};
