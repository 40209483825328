import { Editor, Node, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";

export const DEFAULT_NODE = "paragraph";

export const hasBlock = (type: string, value: Node[]) => {
  return value.some(node => (node ? node.type === type : false));
};

export const isBlockActive = (editor: Editor, type: string) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === type
  });

  return !!match;
};

export const toggleBlock = (editor: Editor, type: string) => {
  const isActive = isBlockActive(editor, type);

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : type
  });

  return isActive;
};

export const insertText = (
  text: string,
  editor: Editor & ReactEditor,
  selection: Range | null
) => {
  Transforms.insertText(
    editor,
    text,
    selection ? { at: selection } : undefined
  );
  ReactEditor.focus(editor);
};

export const removeBlockOnReturn = (
  e: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor
) => {
  if (e.key !== "Enter") {
    return;
  }

  if (!editor.selection) {
    return;
  }

  const [match] = Editor.nodes(editor, {
    match: n =>
      n.type === "header-three" ||
      n.type === "header-four" ||
      n.type === "recipe-link"
  });

  if (!match) {
    return;
  }

  const p1 = Editor.start(editor, editor.selection);
  const p2 = Editor.end(editor, editor.selection);

  // use default when there is a selection
  if (p1.offset !== p2.offset) {
    return;
  }

  // use default if cursor is not at the end
  if (Node.string(match[0]).length !== p2.offset) {
    return;
  }

  e.preventDefault();
  Editor.insertBreak(editor);
  Transforms.setNodes(editor, { type: "paragraph" });
};

export const withPasteBlocks = (editor: Editor & ReactEditor) => {
  const { insertData } = editor;

  editor.insertData = data => {
    const fragment = data.getData("application/x-slate-fragment");

    if (fragment) {
      const decoded = decodeURIComponent(window.atob(fragment));
      const parsed = JSON.parse(decoded) as Node[];
      parsed.forEach((n, i) => {
        if (i === 0) {
          editor.insertText(Node.string(n));
          Transforms.setNodes(editor, { type: n.type });
        } else {
          editor.insertNode(n);
        }
      });
      return;
    }

    insertData(data);
  };

  return editor;
};
