import React, { useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../shared/ItemTypes";
import {
  useCreateMenuItemsMutation,
  useUpdateMenuItemMutation,
  updateMenuItemMutationOptions,
  createMenuItemsMutationOptions,
  MenuDay,
  MenuSectionColor,
  MenuPlannerQuery,
  MenuPlannerQueryVariables,
  updateMenuNoteMutationOptions,
  useUpdateMenuNoteMutation,
  useCreateMenuNoteMutation,
  createMenuNoteMutationOptions
} from "@saffron/controllers";
import { theme } from "../../../../ui/theme";

interface Props {
  k: number;
  lastSection: boolean;
  gridColumn: number;
  gridRow: number;
  menuItems: MenuPlannerQuery["menuPlanner"];
  md: MenuDay;
  ms: MenuSectionColor;
  month?: boolean;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
}

export const MenuSectionDragSource: React.FC<Props> = ({
  children,
  k,
  md,
  ms,
  lastSection,
  gridColumn,
  menuItems,
  gridRow,
  month,
  menuPlannerQueryVariables
}) => {
  const [createMenuItems] = useCreateMenuItemsMutation();
  const [updateMenuItem] = useUpdateMenuItemMutation();
  const [updateMenuNote] = useUpdateMenuNoteMutation();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [ItemTypes.MENU_ITEM, ItemTypes.MENU_NOTE],
    drop: ({ recipe, menuItemId, menuNoteId }) => {
      if (menuNoteId) {
        updateMenuNote(
          updateMenuNoteMutationOptions(
            {
              id: menuNoteId,
              input: {
                date: md.dtKey,
                menuSectionId: ms.id
              }
            },
            menuPlannerQueryVariables,
            undefined,
            true
          )
        );
      } else if (menuItemId) {
        updateMenuItem(
          updateMenuItemMutationOptions(
            {
              menuItem: {
                id: menuItemId,
                date: md.dtKey,
                menuSectionId: ms.id
              }
            },
            menuPlannerQueryVariables
          )
        );
      } else {
        createMenuItems(
          createMenuItemsMutationOptions(
            {
              menuItems: [
                {
                  date: md.dtKey,
                  menuSectionId: ms.id,
                  recipeId: recipe.id
                }
              ]
            },
            [recipe],
            menuPlannerQueryVariables
          )
        );
      }
    },
    collect: mon => ({
      isOver: !!mon.isOver(),
      canDrop: !!mon.canDrop()
    }),
    canDrop: ({ menuItemId, menuNoteId }: any) => {
      return !menuItems.find(x => x.id === menuItemId || x.id === menuNoteId);
    }
  });
  const [createNote] = useCreateMenuNoteMutation();
  const [{ visible, text }, setNoteData] = useState({
    visible: false,
    text: ""
  });

  const submit = () => {
    setNoteData({ visible: false, text: "" });
    const trimmedText = text.replace(/^\s+|\s+$/g, "");
    if (trimmedText) {
      createNote(
        createMenuNoteMutationOptions(
          {
            input: {
              text: trimmedText,
              date: md.dtKey,
              menuSectionId: ms.id
            }
          },
          menuPlannerQueryVariables
        )
      );
    }
  };

  return (
    <div
      ref={drop}
      style={{
        cursor: "pointer",
        minHeight: 40,
        marginTop: !k ? 50 : 0,
        marginBottom: month ? undefined : lastSection ? 40 : 0,
        paddingBottom: lastSection ? 10 : 0,
        paddingLeft: 10,
        backgroundColor: canDrop && isOver ? "#F6F3E1" : undefined,
        paddingRight: 10,
        gridColumn,
        gridRow: gridRow + k + 1
      }}
      onClick={() => setNoteData({ visible: true, text: "" })}
    >
      {children}
      {visible ? (
        <form
          onSubmit={e => {
            e.preventDefault();
            submit();
          }}
        >
          <textarea
            style={{
              resize: "none",
              fontFamily: theme.uiFontStack,
              color: "#444",
              outline: "none",
              border: "none",
              fontSize: 14,
              padding: 0,
              background: "transparent",
              width: "100%",
              margin: "0 4px"
            }}
            autoFocus
            onClick={e => e.stopPropagation()}
            placeholder="note..."
            value={text}
            onBlur={() => {
              submit();
            }}
            ref={textAreaRef}
            onKeyDown={e => {
              if (e.key === "Enter" && e.shiftKey === false) {
                e.preventDefault();
                submit();
                return;
              }
            }}
            onChange={e => {
              setNoteData({ visible: true, text: e.target.value });
              if (textAreaRef.current) {
                textAreaRef.current.style.height = "";
                textAreaRef.current.style.height =
                  textAreaRef.current.scrollHeight + "px";
              }
            }}
          />
        </form>
      ) : null}
    </div>
  );
};
