import { CookbookAccessLevel, useCookbooksQuery } from "@saffron/controllers";
import "cropperjs/dist/cropper.css";
import React from "react";
import { RouteComponentProps } from "react-router";
import NotFound from "../../misc/error/NotFound";
import { CookbookCoverForm } from "./CookbookCoverForm";

const UpdateCookbookCover: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id }
  }
}) => {
  // we don't need shared, but we will fetch them anyway
  // because we have it in the cache already
  const { data, loading } = useCookbooksQuery();
  if (loading) {
    return null;
  }

  const cookbook = data && data.cookbooks.find(x => x.id === id);

  if (
    !cookbook ||
    (cookbook.accessLevel !== CookbookAccessLevel.Editor &&
      cookbook.accessLevel !== CookbookAccessLevel.Owner)
  ) {
    return <NotFound />;
  }

  return <CookbookCoverForm cookbook={cookbook} />;
};

export default UpdateCookbookCover;
