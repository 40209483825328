import {
  formatRecipeFormValues,
  normalizeErrors,
  slugify
} from "@saffron/common";
import {
  updateAfterCreateRecipe,
  useCreateRecipeMutation
} from "@saffron/controllers";
import qs from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { pushLocation } from "../../../utils/pushLocation";
import { useConfirmLeave } from "../../../utils/useConfirmLeave";
import { notificationState } from "../../misc/notifications/NotificationState";
import { RecipeForm } from "../shared/RecipeForm";

const CreateRecipeConnector: React.FC<RouteComponentProps<{
  cookbookId: string;
  sectionId: string;
}>> = ({ match: { params }, location, history }) => {
  const [loading, setLoading] = useState(false);
  const requestStarted = useRef(false);
  const [createRecipe] = useCreateRecipeMutation();

  useEffect(() => {
    if (location.search) {
      history.replace(
        `/new-recipe/cookbook/${params.cookbookId}/${params.sectionId}`
      );
    }
  }, [history, location, params]);

  useConfirmLeave();

  let webImportOpen = false;
  let textImportOpen = false;

  if (location.search) {
    const queryParams = qs.parse(location.search);
    if ("website-import" in queryParams) {
      webImportOpen = true;
    }
    if ("text-import" in queryParams) {
      textImportOpen = true;
    }
  }

  return (
    <RecipeForm
      textImportOpen={textImportOpen}
      webImportOpen={webImportOpen}
      loading={loading}
      showImportRecipeButton={true}
      headerText="Import a recipe from:"
      initialValues={{ ...params }}
      submit={async formValues => {
        if (requestStarted.current) {
          return;
        }
        requestStarted.current = true;
        setLoading(true);

        let picture: Blob | null = null;
        if (
          formValues.croppedBase64Img &&
          !formValues.croppedBase64Img.startsWith("http")
        ) {
          const res = await fetch(formValues.croppedBase64Img);
          picture = await res.blob();
        }

        let response;

        try {
          const variables = {
            recipe: {
              ...formatRecipeFormValues(formValues),
              picture
            }
          };
          response = await createRecipe({
            variables,
            update: updateAfterCreateRecipe(variables, formValues.cookbookId!)
          });
        } catch {}

        if (!response || !response.data) {
          requestStarted.current = false;
          setLoading(false);
          return null;
        }

        const {
          createRecipe: { errors, recipe }
        } = response.data;

        if (errors) {
          requestStarted.current = false;
          setLoading(false);
          return normalizeErrors(errors);
        }

        // if (uploadUrl && formValues.croppedBase64Img) {
        //   await uploadToS3(uploadUrl, formValues.croppedBase64Img);
        // }

        if (recipe) {
          pushLocation(
            `/browse/${params.cookbookId}/${params.sectionId}/${
              recipe.id
            }/${slugify(recipe.name)}`
          );

          if (formValues.pictureUrl && !recipe.pictureUrl) {
            notificationState.send({
              variant: "success",
              text: "Unable to import photo from website."
            });
          }
        }

        requestStarted.current = false;

        return null;
      }}
    />
  );
};

export default CreateRecipeConnector;
