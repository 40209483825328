import React, { Suspense } from "react";
import { Link, Route, Router, Switch } from "react-router-dom";
import history from "../history";
import BrowseCookbookConnector from "../modules/cookbook/BrowseCookbook/BrowseCookbookView";
import { BrowseFromLocalStorage } from "../modules/cookbook/BrowseCookbook/BrowseFromLocalStorage";
import UnlistedCookbook from "../modules/cookbook/UnlistedCookbook";
import UpdateCookbookCover from "../modules/cookbook/UpdateCookbookCover";
import GroceryListConnector from "../modules/grocery/GroceryList/GroceryListConnector";
import ViewMenuPlannerContainer from "../modules/menuPlanner/viewMenuPlanner/ViewMenuPlannerContainer";
import NotAuthorized from "../modules/misc/error/NotAuthorized";
// too simple to code-split
import NotFound from "../modules/misc/error/NotFound";
import UnexpectedError from "../modules/misc/error/UnexpectedError";
import { NotificationManager } from "../modules/misc/notifications/NotificationManager";
import Onboarding from "../modules/misc/Onboarding";
import { BulkImportPage } from "../modules/recipe/BulkImportPage";
import CreateRecipeConnector from "../modules/recipe/CreateRecipe/CreateRecipeConnector";
import EditRecipeConnector from "../modules/recipe/EditRecipe/EditRecipeConnector";
import SearchRecipeConnector from "../modules/recipe/FindRecipe/FindRecipeConnector";
import FullscreenRecipe from "../modules/recipe/FullscreenRecipe";
// screens
import ViewRecipe from "../modules/recipe/ViewRecipe";
import ViewSharedRecipe from "../modules/recipe/ViewSharedRecipe";
import { Lifecycle } from "../modules/shared/Lifecycle";
import AccountStatus from "../modules/user/AccountStatus";
import { BookmarkButtonCreator } from "../modules/user/BookmarkButtonCreator";
import EmailConfirmation from "../modules/user/EmailConfirmation";
import ChangePassword from "../modules/user/ForgotPassword/ChangePassword";
import ForgotPassword from "../modules/user/ForgotPassword/ForgotPassword";
import ForgotPasswordCheckEmail from "../modules/user/ForgotPassword/ForgotPasswordCheckEmail";
import LoginConnector from "../modules/user/Login/LoginConnector";
import OAuthLogin from "../modules/user/Login/OAuthLogin";
import Logout from "../modules/user/Logout";
import RegisterConnector from "../modules/user/Register/RegisterConnector";
import { Flex } from "../ui/Flex";
import MainGrid from "../ui/MainGrid";
import NavBar from "../ui/NavBar";
import { ErrorBoundary } from "../utils/ErrorBoundary";
import { AdminRoute } from "./auth/AdminRoute";
import { AuthRoute } from "./auth/AuthRoute";

const AdminRoutes = React.lazy(() => import("../modules/admin/AdminRoutes"));

const Routes = () => {
  return (
    <Suspense fallback={null}>
      <Router history={history as any}>
        <MainGrid>
          <ErrorBoundary>
            <Switch>
              <Route
                path="/"
                exact
                render={({ location }) => {
                  // no one should come to the landing page
                  // but if they do, redirect them to gatsby
                  return (
                    <>
                      <Link style={{ fontSize: 40, padding: 20 }} to="/login">
                        login
                      </Link>
                      <Lifecycle
                        onComponentDidMount={() => {
                          if (!location.search) {
                            // @ts-ignore
                            window.location = "/?ref=cra";
                          }
                        }}
                      />
                    </>
                  );
                }}
              />
              <Route
                path={["/login", "/login/na"]}
                exact
                component={LoginConnector}
              />
              <Route path="/oauth" exact component={OAuthLogin} />
              <Route path="/register" exact component={RegisterConnector} />
              <Route path="/confirmation" exact component={EmailConfirmation} />
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route
                path={["/change-password/:token", "/a/change-password/:token"]}
                exact
                component={ChangePassword}
              />
              <Route path="/logout" exact component={Logout} />
              <Route
                path="/forgot-password-check-email"
                exact
                component={ForgotPasswordCheckEmail}
              />
              <Route path="/not-authorized" exact component={NotAuthorized} />
              <Route
                path={["/recipe/:id/:slug/fullscreen"]}
                exact
                component={FullscreenRecipe}
              />
              <Route
                path={[
                  "/cookbooks/:shareId/:slug",
                  "/a/cookbooks/:shareId/:slug"
                ]}
                exact
                component={UnlistedCookbook}
              />
              <Route path="/">
                <Flex
                  flexDirection="row"
                  width="100%"
                  style={{ minHeight: "100%" }}
                >
                  <NavBar />
                  <Suspense fallback={null}>
                    <Switch>
                      <AuthRoute
                        path="/cookbook/cover/:id"
                        component={UpdateCookbookCover}
                      />
                      <Route
                        path={["/a/recipe/:id/:slug", "/recipe/:id/:slug"]}
                        exact
                        component={ViewRecipe}
                      />
                      <Route
                        path={[
                          "/a/shared/recipe/:shareId/:slug",
                          "/shared/recipe/:shareId/:slug"
                        ]}
                        exact
                        component={ViewSharedRecipe}
                      />
                      <AuthRoute
                        path="/new-recipe/cookbook/:cookbookId/:sectionId"
                        component={CreateRecipeConnector}
                      />
                      <AuthRoute
                        path="/recipe/edit/:id/:slug"
                        component={EditRecipeConnector}
                      />
                      <AuthRoute path="/account" component={AccountStatus} />
                      <AuthRoute
                        exact
                        path="/create-bookmark"
                        component={BookmarkButtonCreator}
                      />
                      <AuthRoute
                        path="/onboarding"
                        exact
                        component={Onboarding}
                      />
                      <Route
                        path="/unexpected-error"
                        exact
                        component={UnexpectedError}
                      />
                      <Route
                        path={["/go/browse"]}
                        exact
                        component={BrowseFromLocalStorage}
                      />
                      <Route
                        path={[
                          "/browse",
                          "/browse/:currentCookbookId/:currentSectionId?/:currentRecipeId?/:slug?"
                        ]}
                        exact
                        component={BrowseCookbookConnector}
                      />
                      <AuthRoute
                        path="/search"
                        exact
                        component={SearchRecipeConnector}
                      />
                      <Route
                        path={[
                          "/menu-planner/(week|month)",
                          "/menu-planner/(week|month)/:year/:month/:day"
                        ]}
                        exact
                        component={ViewMenuPlannerContainer}
                      />
                      <Route
                        path="/grocery-list"
                        exact
                        component={GroceryListConnector}
                      />
                      <AuthRoute
                        path="/bulk-import"
                        exact
                        component={BulkImportPage}
                      />
                      <AdminRoute path="/admin" component={AdminRoutes} />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </Suspense>
                </Flex>
              </Route>
            </Switch>
            <NotificationManager />
          </ErrorBoundary>
        </MainGrid>
      </Router>
    </Suspense>
  );
};

export default Routes;
