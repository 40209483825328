import * as React from "react";
import { useGetSharedRecipeQuery, useMeQuery } from "@saffron/controllers";
import NotFound from "../misc/error/NotFound";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { slugify } from "@saffron/common";
import { SideBarLayout } from "../../ui/Layout/SidebarLayout";
import { SharedRecipeHeader } from "./shared/SharedRecipeHeader";
import Recipe from "../../ui/Recipe";
import { RecipeNoteSidebar } from "../shared/RecipeNoteSidebar";
import { MainContent } from "../../ui/Layout/MainContent";
import { patternWidthValue } from "../../ui/theme";
import styled from "styled-components";
import { media } from "../../ui/mediaTemplates";
import Media from "react-media";

type Props = RouteComponentProps<{ shareId: string; slug: string }>;

const Container = styled.div`
  flex: 1;
  margin-bottom: 2em;
  padding-left: 0px;
  ${media.leftNavDies`
  padding-left: ${patternWidthValue}px;
  `}
`;

const ViewSharedRecipe: React.FC<Props> = ({
  match: {
    params: { shareId, slug }
  }
}) => {
  const [showNotes, setShowNotes] = React.useState(false);
  const { data: meData } = useMeQuery();
  const { data, loading } = useGetSharedRecipeQuery({ variables: { shareId } });
  if (!data || loading) {
    return null;
  }

  if (!data.getSharedRecipe) {
    return <NotFound />;
  }

  if (data.getSharedRecipe) {
    const correctSlug = slugify(data.getSharedRecipe.name);
    if (slug !== correctSlug) {
      return (
        <Redirect
          to={`/a/shared/recipe/${data.getSharedRecipe.shareId}/${correctSlug}`}
        />
      );
    }
  }

  const inside = (
    <Recipe
      type={
        meData?.me.user?.id === data.getSharedRecipe.owner ? "owner" : "viewer"
      }
      disableRecipeLinks
      notesOpen={showNotes}
      onNotesClicked={() => setShowNotes(!showNotes)}
      {...data.getSharedRecipe}
    />
  );

  return (
    <SideBarLayout>
      <Media
        queries={{
          small: "(max-width: 650px)"
        }}
      >
        {({ small }) => (
          <>
            {showNotes ? (
              <RecipeNoteSidebar
                fullscreen={small}
                onRequestClose={() => setShowNotes(false)}
                recipe={data.getSharedRecipe!}
              />
            ) : null}
            {showNotes ? (
              small ? null : (
                <MainContent>{inside}</MainContent>
              )
            ) : (
              <Container>
                <SharedRecipeHeader />
                {inside}
              </Container>
            )}
          </>
        )}
      </Media>
    </SideBarLayout>
  );
};

export default ViewSharedRecipe;
