import {
  CookbookAccessLevel,
  RecipePreviewFragment,
  useMeQuery,
  RecipeAccessLevel,
  CookbookSectionLookupInfo
} from "@saffron/controllers";
import React from "react";
import { AddGroceryItemsRicon } from "../AddGroceryItemsRicon";
import { CopyForRedditRicon } from "../CopyForRedditRicon";
import { DeleteRicon } from "../DeleteRicon";
import { EditRicon } from "../EditRicon";
import { ExpandRicon } from "../ExpandRicon";
import { MenuPlannerRicon } from "../MenuPlannerRicon";
import { MoreRicon } from "../MoreRicon";
import { PdfRicon } from "../PdfRicon";
import { RiconContainer } from "../RiconContainer";
import { ScaleRicon } from "../ScaleRicon";
import { ShareRicon } from "../ShareRicon";

type Props = {
  onEditRiconClick: () => void;
  recipe: RecipePreviewFragment;
  onDeleteRiconClick?: () => void;
  noFullscreen?: boolean;
  recipeAccessLevel: RecipeAccessLevel;
  cookbookOptions?: {
    cookbookSectionLookupInfo: CookbookSectionLookupInfo;
    onClone: (r: RecipePreviewFragment) => void;
  };
};

export const RiconBar: React.FC<Props> = ({
  recipe: { ingredients, id, name, instructions },
  recipe,
  cookbookOptions,
  recipeAccessLevel,
  onEditRiconClick,
  onDeleteRiconClick,
  noFullscreen
}) => {
  const { data, loading } = useMeQuery();
  if (!data || loading || !data.me || !data.me.user) {
    return null;
  }

  const isAdmin = data.me.user.role.type === "admin";

  return (
    <RiconContainer>
      {recipeAccessLevel !== CookbookAccessLevel.Viewer ? (
        <EditRicon onClick={onEditRiconClick} />
      ) : null}
      <AddGroceryItemsRicon
        ingredients={ingredients}
        recipeId={id}
        recipeName={name}
      />
      <MenuPlannerRicon recipe={recipe} />
      {!noFullscreen && <ExpandRicon recipe={recipe} />}
      <ScaleRicon recipe={recipe} />
      {recipeAccessLevel === CookbookAccessLevel.Owner && (
        <ShareRicon recipe={recipe} />
      )}
      <PdfRicon recipe={recipe} />
      {/* {!isOwner && <AddRecipeRicon recipeId={id} />} */}
      {isAdmin && (
        <CopyForRedditRicon
          instructions={instructions}
          ingredients={ingredients}
        />
      )}
      {recipeAccessLevel !== CookbookAccessLevel.Viewer ? (
        <DeleteRicon recipeId={id} onDelete={onDeleteRiconClick} />
      ) : null}
      {cookbookOptions && (
        <MoreRicon
          recipeAccessLevel={recipeAccessLevel}
          user={data.me.user}
          recipe={recipe}
          {...cookbookOptions}
        />
      )}
    </RiconContainer>
  );
};
