import { Omit } from "@saffron/common";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled, { css } from "styled-components";
import { ErrorText } from "../ErrorText";
import { theme } from "../theme";

interface PrettyInputProps {
  error?: boolean;
}

const common = css<{ error?: boolean }>`
  color: #444;
  width: 100%;
  background-color: transparent;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid ${props => (props.error ? "#D13C1E" : "#e6e6e6")} 1.2px;
  padding-bottom: 5px;
  font-size: 1rem;
`;

const focusCommon = css<{ error?: boolean }>`
  border-bottom: solid ${props => (props.error ? "#D13C1E" : "#a4c8d2")} 1.2px;
`;

const placeholderCommon = css<{ error?: boolean }>`
  color: #b3b3b3;
`;

export const PrettyInput = styled("input")<PrettyInputProps>`
  ${common}

  &:focus {
    ${focusCommon}
  }
  &::placeholder {
    ${placeholderCommon}
  }
`;

const PrettyTextArea = styled("div")<PrettyInputProps>`
  .resize-textarea {
    ${common}
    resize: none;
    line-height: 1.5rem;
  }
  .resize-textarea:focus {
    ${focusCommon}
  }
  .resize-textarea::placeholder {
    ${placeholderCommon}
  }
`;

export const LabelText = styled("div")`
  color: #444;
  font-family: ${theme.fonts.ui};
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding-top: 4px;
`;

export type InputProps = {
  autoFocus?: boolean;
  value: any;
  placeholder?: string;
  errorText?: string | null;
  containerStyle?: any;
  style?: any;
  icon?: JSX.Element;
  inputRef?: any;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export class Input extends React.PureComponent<InputProps> {
  render() {
    const {
      errorText,
      placeholder,
      containerStyle = {},
      icon,
      style = {},
      inputRef,
      ...inputProps
    } = this.props;

    return (
      <div style={containerStyle}>
        <LabelText>{placeholder}</LabelText>
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute" }}>{icon}</div>
          <PrettyInput
            style={{
              ...style,
              textIndent: icon ? "1.25em" : undefined
            }}
            error={!!errorText}
            ref={inputRef}
            {...inputProps}
          />
        </div>
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </div>
    );
  }
}

export const TextArea: React.FC<Omit<InputProps, "inputRef"> & {
  hint?: string;
  maxRows?: number;
}> = ({
  errorText,
  placeholder,
  containerStyle = {},
  icon,
  style = {},
  hint,
  ...inputProps
}) => {
  return (
    <PrettyTextArea error={!!errorText} style={containerStyle}>
      <LabelText>{placeholder}</LabelText>
      <TextareaAutosize
        className="resize-textarea"
        placeholder={hint}
        style={{
          fontFamily: theme.fonts.ui,
          ...style
        }}
        {...(inputProps as any)}
      />
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </PrettyTextArea>
  );
};
