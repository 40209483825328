import { NetworkStatus } from "@apollo/client";
import { slugify, sortBy } from "@saffron/common";
import {
  CookbookAccessLevel,
  CookbooksQuery,
  JustSectionFragment,
  toChildAccess,
  useCookbooksQuery,
  useGetRecipeByIdWithCookbooksQuery,
  useMeQuery,
  useSectionsByCookbookIdQuery
} from "@saffron/controllers";
import * as React from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { CookbookBreadcrumbs } from "../../../ui/CookbookBreadcrumbs";
import { Flex } from "../../../ui/Flex";
import { MainContent } from "../../../ui/Layout/MainContent";
import { SideBarLayout } from "../../../ui/Layout/SidebarLayout";
import Recipe from "../../../ui/Recipe";
import { RecipeWrapper } from "../../../ui/Recipe/components";
import { pushLocation } from "../../../utils/pushLocation";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import { usePrevious } from "../../../utils/usePrevious";
import { RiconBar } from "../../ricons/RiconBar";
import { RecipeNoteSidebar } from "../../shared/RecipeNoteSidebar";
import { CookbookSidebar, LS_COOKBOOK_ORDER } from "./CookbookSidebar";
import { makeBrowseUrl } from "./makeBrowseUrl";
import { IdStuff } from "./types";

export const BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY = "browse-cookbook-state";

const BrowseCookbookView: React.FC<RouteComponentProps<IdStuff["ids"]>> = ({
  history,
  match: { params: ids },
  location
}) => {
  const { data: meData } = useMeQuery();
  const [showNotes, setShowNotes] = React.useState(false);

  const setIds: IdStuff["setIds"] = fnOrObj => {
    const d = typeof fnOrObj === "function" ? fnOrObj(ids) : fnOrObj;

    pushLocation(makeBrowseUrl(d), "replace");
  };
  // const [ids, setIds] = React.useState<{
  //   currentRecipeId?: string;
  //   currentSectionId?: string;
  //   currentCookbookId?: string;
  // }>(() => {
  //   if (
  //     params.currentCookbookId &&
  //     isUuid(params.currentCookbookId) &&
  //     (params.currentCookbookId ||
  //       params.currentSectionId ||
  //       params.currentRecipeId)
  //   ) {
  //     return params;
  //   }

  //   const sState = localStorage.getItem(
  //     BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY
  //   );

  //   if (!sState) {
  //     return {};
  //   }

  //   try {
  //     const state = JSON.parse(sState);
  //     if (typeof state === "string") {
  //       return {};
  //     }
  //     return state;
  //   } catch {
  //     return {};
  //   }
  // });

  // const previousParams = usePrevious(params);

  // useEffect(() => {
  //   if (params === previousParams || !params.currentCookbookId) {
  //     return;
  //   }

  //   setIds(params);
  // }, [params, previousParams]);

  useEffect(() => {
    try {
      localStorage.setItem(
        BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY,
        JSON.stringify(ids)
      );
      return;
    } catch {}
    try {
      localStorage.setItem(
        BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY,
        JSON.stringify({})
      );
    } catch {}
  }, [ids]);

  const { data: recipeData } = useGetRecipeByIdWithCookbooksQuery({
    variables: { id: ids.currentRecipeId! },
    skip: !ids.currentRecipeId
  });

  const isCurrent = React.useRef(true);

  useEffect(() => {
    return () => {
      isCurrent.current = false;
    };
  }, []);

  const pKey = usePrevious(location.key);
  useEffect(() => {
    if (showNotes && pKey && location.key && pKey !== location.key) {
      setShowNotes(false);
    }
  }, [showNotes, pKey, location.key]);

  const currentId = recipeData?.getRecipeById?.id;
  const previousId = usePrevious(currentId);
  useEffect(() => {
    if (!currentId || !previousId) {
      return;
    }

    if (currentId !== previousId) {
      window.scrollTo(0, 0);
    }
  }, [previousId, currentId]);

  const [cookbookOrder, setCookbookOrder] = useLocalStorage<
    Record<string, number>
  >(LS_COOKBOOK_ORDER, {});

  const { data: cbData, loading: cbLoading } = useCookbooksQuery();
  const {
    data: sectionData,
    networkStatus: sectionNetworkStatus
  } = useSectionsByCookbookIdQuery({
    variables: { cookbookId: ids.currentCookbookId! },
    skip: !ids.currentCookbookId,
    notifyOnNetworkStatusChange: true
  });

  let cookbooks: CookbooksQuery["cookbooks"] = [];
  let currentCookbook: CookbooksQuery["cookbooks"][0] | undefined = undefined;

  if (cbData && cbData.cookbooks) {
    cookbooks = sortBy(cbData.cookbooks, x => cookbookOrder[x.id] ?? -1);
    currentCookbook = cookbooks.find(x => x.id === ids.currentCookbookId);
  }

  let sections: JustSectionFragment[] = [];
  const sectionsLoading =
    sectionNetworkStatus === NetworkStatus.setVariables ||
    sectionNetworkStatus === NetworkStatus.loading;
  let currentSection: JustSectionFragment | undefined = undefined;

  if (sectionData && sectionData.sectionsByCookbookId) {
    sections = sortBy(sectionData.sectionsByCookbookId, "position");
    currentSection = sections.find(x => x.id === ids.currentSectionId);
  }

  if (sectionNetworkStatus === NetworkStatus.setVariables) {
    sections = [];
  }

  const recipeAccessLevel = !recipeData?.getRecipeById
    ? CookbookAccessLevel.Viewer
    : toChildAccess(
        recipeData.getRecipeById.owner,
        meData?.me.user?.id,
        currentCookbook?.accessLevel
      );

  return (
    <SideBarLayout>
      {showNotes && recipeData && recipeData.getRecipeById ? (
        <RecipeNoteSidebar
          cookbookAccessLevel={currentCookbook?.accessLevel}
          onRequestClose={() => setShowNotes(false)}
          recipe={recipeData.getRecipeById}
        />
      ) : (
        <CookbookSidebar
          sections={sections}
          sectionsLoading={sectionsLoading}
          cookbooks={cookbooks}
          currentCookbook={currentCookbook}
          setCookbookOrder={setCookbookOrder}
          cbLoading={cbLoading}
          ids={ids}
          setIds={setIds}
        />
      )}
      <MainContent>
        <Flex flexDirection="column">
          {recipeData && recipeData.getRecipeById && (
            <React.Fragment key={recipeData.getRecipeById.id}>
              <RiconBar
                recipeAccessLevel={recipeAccessLevel}
                cookbookOptions={
                  currentCookbook && currentSection
                    ? {
                        cookbookSectionLookupInfo: {
                          cookbook: currentCookbook,
                          section: currentSection
                        },
                        onClone: r => {
                          setIds({
                            ...ids,
                            currentRecipeId: r.id,
                            slug: r.name
                          });
                        }
                      }
                    : undefined
                }
                recipe={recipeData.getRecipeById}
                onEditRiconClick={() => {
                  history.push(
                    `/recipe/edit/${recipeData.getRecipeById!.id}/${slugify(
                      recipeData.getRecipeById!.name
                    )}`,
                    { next: `/browse` }
                  );
                }}
                onDeleteRiconClick={() =>
                  setIds({ ...ids, currentRecipeId: undefined })
                }
              />
              {recipeData.getRecipeById.cookbookSectionLookup ? (
                <RecipeWrapper style={{ marginTop: "1.25em", marginBottom: 0 }}>
                  <CookbookBreadcrumbs
                    cookbookSectionLookup={
                      recipeData.getRecipeById.cookbookSectionLookup
                    }
                    recipe={recipeData.getRecipeById}
                    recipeAccessLevel={recipeAccessLevel}
                  />
                </RecipeWrapper>
              ) : null}
              <Flex flex={1}>
                <Recipe
                  type={
                    currentCookbook &&
                    (currentCookbook.accessLevel ===
                      CookbookAccessLevel.Owner ||
                      currentCookbook.accessLevel ===
                        CookbookAccessLevel.Editor)
                      ? "owner"
                      : "viewer"
                  }
                  onNotesClicked={() => {
                    setShowNotes(!showNotes);
                  }}
                  notesOpen={showNotes}
                  {...recipeData.getRecipeById}
                />
              </Flex>
            </React.Fragment>
          )}
        </Flex>
      </MainContent>
    </SideBarLayout>
  );
};

export default BrowseCookbookView;
