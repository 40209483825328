import React from "react";
import { RouteComponentProps } from "react-router";
import { parse } from "query-string";
import { LoginView } from "./LoginView";
import { normalizeErrors } from "@saffron/common";

const OAuthLogin: React.FC<RouteComponentProps> = ({ location }) => {
  const parsed = parse(location.search);

  if (
    !parsed.client_id ||
    !parsed.redirect_uri ||
    !parsed.response_type ||
    !parsed.scope ||
    !parsed.state
  ) {
    return <div>something went wrong</div>;
  }

  return (
    <LoginView
      noForgotPassword
      noRegisterLink
      handleLogin={async ({ email, password }) => {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/oauth_login`,
          {
            method: "POST",
            body: JSON.stringify({
              email: email?.trim(),
              password,
              client_id: parsed.client_id as string,
              redirect_uri: parsed.redirect_uri as string,
              response_type: parsed.response_type as string,
              scope: parsed.scope as string,
              state: parsed.state as string
            }),
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        const data = await response.json();
        if (data.redirectUrl) {
          window.location.replace(data.redirectUrl);
          return null;
        }

        if (data?.errors) {
          return normalizeErrors(data.errors);
        }

        return null;
      }}
    />
  );
};

export default OAuthLogin;
