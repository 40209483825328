import {
  formatMinutes,
  formatUrl,
  ingredientToParts,
  scaleIngredient,
  slugify
} from "@saffron/common";
import { RecipePreviewFragment, RegularIngredient } from "@saffron/controllers";
import React, { useEffect } from "react";
import { Node } from "slate";
import styled, { createGlobalStyle } from "styled-components";
import { Subscribe } from "unstated";
import { PrettyLink, PrettyMyLink } from "../../ui/PrettyLink";
import {
  AuthorSection,
  Description,
  IngredientHeader,
  instructionPaddingBottomClassname,
  Name,
  RecipeWrapper,
  TimeSection,
  TimeType,
  TimeValue
} from "../../ui/Recipe/components";
import { ScalingBadge } from "../../ui/Recipe/ScalingBadge";
import { theme } from "../../ui/theme";
import { slateInstructionsToReact } from "../../utils/slateInstructionsToReact";
import { ScaleIgContainer } from "../ricons/ScaleIgContainer";

// this is for firefox
// so more than 1 page actually prints
const BlockEverything = createGlobalStyle`
@media print {
    html, body * {
      display: block !important;
      height: auto;
    }
    script {
      display: none !important;
    }
    html {
      font-size: 12px;
    }
    html, body {
      background-color: #fff;
    }
  }
  `;

const Strong = styled.strong`
  color: #333;
  display: inline !important;
`;

const NoGridUlInstructions = styled("ul")`
  list-style: none;
  font-family: ${theme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  margin-left: 0;
  margin-bottom: 0.64em;
  line-height: 1.563;
  padding: 0px;
  li {
    ${instructionPaddingBottomClassname};
  }
`;
// max-width: 525px;

const TopContainer = styled.div`
  display: flex !important;
`;

const IngredientContainer = styled.div`
  & > div {
    margin-bottom: 16px;
  }
  & > a {
    margin-bottom: 16px;
  }
  & > h3 {
    margin-bottom: 12px;
  }
`;

export const PrintableRecipe: React.FC<{
  recipe: RecipePreviewFragment;
  showImg: boolean;
}> = ({ recipe, showImg }) => {
  useEffect(() => {
    document.title = slugify(recipe.name || "saffron");
  }, [recipe]);

  const {
    name,
    pictureUrl,
    ingredients,
    instructions,
    times = [],
    servings,
    source,
    sourceUrl,
    description
  } = recipe;

  const sourceText = (() => {
    if (source) {
      return source;
    }
    if (sourceUrl) {
      try {
        return new URL(sourceUrl).host || "";
      } catch {}
    }

    return "";
  })();

  let rawInstructions: Node[] = [];

  if (instructions) {
    try {
      rawInstructions = JSON.parse(instructions);
    } catch {}
  }

  const formattedUrl = sourceUrl && formatUrl(sourceUrl);

  return (
    <>
      <BlockEverything />
      <div
        style={{
          flex: 1,
          height: "100%"
        }}
      >
        <RecipeWrapper>
          {name ? (
            <Name data-testid="recipe-name" descriptionBelow={!!description}>
              {name}
            </Name>
          ) : null}
          {description ? (
            <Description style={{ paddingBottom: 16 }}>
              {description}
            </Description>
          ) : null}
          {pictureUrl && showImg ? (
            <div
              style={{
                width: "100%",
                paddingTop: "50%",
                position: "relative",
                marginBottom: 0,
                backgroundColor: "#f2f2f2"
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden"
                }}
              >
                <img
                  style={{ margin: "auto" }}
                  key={pictureUrl}
                  src={pictureUrl}
                  alt="recipe"
                  data-testid="recipe-picture"
                />
              </div>
            </div>
          ) : null}
          <TopContainer style={{ marginBottom: 40 }}>
            <AuthorSection
              style={{
                width: "30%",
                marginRight: "44px"
              }}
            >
              {source || formattedUrl ? (
                <div data-testid="recipe-source">
                  {formattedUrl ? (
                    <PrettyLink
                      style={{ fontWeight: 800, wordBreak: "break-word" }}
                      data-testid="recipe-sourceUrl"
                      target="blank"
                      href={formattedUrl}
                    >
                      {sourceText}
                    </PrettyLink>
                  ) : (
                    source
                  )}
                </div>
              ) : null}
              {servings ? (
                <div data-testid="recipe-servings">{`${
                  /^\d+$/.test(servings) ? "Serves: " : ""
                }${servings}`}</div>
              ) : null}
            </AuthorSection>
            <TimeSection style={{ marginTop: 10, paddingLeft: 0, flex: 1 }}>
              {times &&
                times.map((t, i) => (
                  <div
                    key={i}
                    style={{
                      borderLeft: i ? "1px solid #DDE2E3" : "",
                      paddingLeft: i === 0 ? 0 : "1em",
                      paddingRight: i === times.length - 1 ? 0 : "1em"
                    }}
                  >
                    <TimeType data-testid={`recipe-time-type-${i}`}>
                      {t.type}
                    </TimeType>
                    <TimeValue data-testid={`recipe-time-value-${i}`}>
                      {formatMinutes(t.value)}
                    </TimeValue>
                  </div>
                ))}
            </TimeSection>
          </TopContainer>
          <div>
            <Subscribe to={[ScaleIgContainer]}>
              {({ state: { amountMap }, setOpen }: ScaleIgContainer) => {
                const multiplier = recipe.id ? amountMap[recipe.id] : null;
                const currentlyScaling = recipe.id && multiplier;
                return (
                  <IngredientContainer
                    style={{
                      minHeight: "100%",
                      lineHeight: "1.35",
                      marginRight: "44px",
                      float: "left",
                      width: "30%"
                    }}
                  >
                    {ingredients.map((ig, i) => {
                      if (ig.__typename === "IngredientHeader") {
                        return (
                          <IngredientHeader key={i}>{ig.text}</IngredientHeader>
                        );
                      }
                      if (currentlyScaling) {
                        ig = scaleIngredient(
                          ig,
                          multiplier!
                        ) as RegularIngredient;
                      }

                      const { amountAndUnit, rest } = ingredientToParts(ig);

                      const inside = (
                        <div style={{ wordBreak: "break-word", width: "100%" }}>
                          <Strong>{amountAndUnit}</Strong>
                          {amountAndUnit && rest ? " " : null}
                          {rest}
                        </div>
                      );

                      return (
                        <React.Fragment key={i}>
                          {ig.__typename === "RecipeLink" ? (
                            <PrettyMyLink
                              to={`/recipe/${ig.recipeId}/${slugify(ig.name)}`}
                            >
                              {inside}
                            </PrettyMyLink>
                          ) : (
                            inside
                          )}
                        </React.Fragment>
                      );
                    })}
                    {currentlyScaling ? (
                      <ScalingBadge
                        onClick={() => setOpen(true)}
                        amount={multiplier!}
                      />
                    ) : null}
                  </IngredientContainer>
                );
              }}
            </Subscribe>
            <NoGridUlInstructions
              style={{
                width: "100%"
              }}
            >
              {slateInstructionsToReact(rawInstructions)}
            </NoGridUlInstructions>
          </div>
        </RecipeWrapper>
      </div>
    </>
  );
};

export class PrintableRecipeClass extends React.Component<{
  show: boolean;
  showImg: boolean;
  recipe: RecipePreviewFragment;
}> {
  render() {
    return this.props.show ? (
      <PrintableRecipe
        showImg={this.props.showImg}
        recipe={this.props.recipe}
      />
    ) : (
      <div />
    );
  }
}
