import React, { useState } from "react";
import {
  RecipePreviewFragment,
  useUpdateRecipeNoteMutation,
  useMeQuery,
  CookbookAccessLevel
} from "@saffron/controllers";
import { Sidebar } from "../../ui/Sidebar";
import { BoldSubhead } from "../../ui/BoldSubhead";
import { DivButton } from "../../ui/DivButton";
import { theme } from "../../ui/theme";
import TextareaAutosize from "react-textarea-autosize";
import { BoldButton, OutlineButton } from "../../ui/Button";
import { ButtonSpinner } from "../../ui/Button/ButtonSpinner";
import { Linkify } from "../../ui/Linkify";

interface NoteSidebarProps {
  cookbookAccessLevel?: CookbookAccessLevel;
  recipe: RecipePreviewFragment;
  onRequestClose: () => void;
  fullscreen?: boolean;
}

export const RecipeNoteSidebar: React.FC<NoteSidebarProps> = ({
  recipe,
  onRequestClose,
  cookbookAccessLevel,
  fullscreen
}) => {
  const { data } = useMeQuery();
  const [updateRecipeNote, { loading }] = useUpdateRecipeNoteMutation();
  const [editMode, setEditMode] = useState(!recipe.notes);
  const [text, setText] = useState(recipe.notes || "");

  const isOwner = recipe.owner === data?.me.user?.id;

  return (
    <Sidebar fullscreen={fullscreen}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 30px",
          paddingBottom: 0
        }}
      >
        <BoldSubhead>Note</BoldSubhead>
        {editMode ? null : (
          <div style={{ display: "flex" }}>
            {isOwner ||
            (cookbookAccessLevel &&
              cookbookAccessLevel !== CookbookAccessLevel.Viewer) ? (
              <DivButton
                style={{
                  color: "#026284",
                  fontSize: "1rem",
                  fontFamily: theme.uiFontStack,
                  marginRight: "1em"
                }}
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Edit
              </DivButton>
            ) : null}
            <DivButton
              style={{
                color: "#026284",
                fontSize: "1rem",
                fontFamily: theme.uiFontStack
              }}
              onClick={() => {
                onRequestClose();
              }}
            >
              Close
            </DivButton>
          </div>
        )}
      </div>
      {editMode ? (
        <>
          <TextareaAutosize
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder="notes..."
            autoFocus
            style={{
              backgroundColor: "#F2F2F2",
              outline: "none",
              border: "none",
              resize: "none",
              lineHeight: "1.5rem",
              margin: "10px 30px",
              marginTop: 8,
              padding: "10px 20px",
              fontSize: "1rem",
              fontFamily: theme.uiFontStack,
              color: "#444"
            }}
          />
          <div
            style={{
              backgroundColor: "#fafafa",
              margin: "10px 30px",
              marginTop: "auto"
            }}
          >
            <div style={{ display: "flex" }}>
              <OutlineButton
                style={{ marginRight: "1em" }}
                type="button"
                color="black"
                onClick={() => {
                  if (!recipe.notes) {
                    onRequestClose();
                  } else {
                    setEditMode(false);
                    setText(recipe.notes || "");
                  }
                }}
              >
                CANCEL
              </OutlineButton>
              <BoldButton
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                disabled={loading}
                onClick={() => {
                  updateRecipeNote({
                    variables: {
                      id: recipe.id,
                      noteText: text
                    }
                  }).then(() => {
                    setEditMode(false);
                  });
                }}
              >
                {loading ? <ButtonSpinner /> : null}
                SAVE NOTE
              </BoldButton>
            </div>
          </div>
        </>
      ) : (
        <pre
          style={{
            flex: 1,
            color: "#444",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            fontFamily: theme.uiFontStack,
            padding: "10px 30px",
            paddingTop: 8,
            marginTop: 0,
            whiteSpace: "pre-wrap",
            overflowY: "auto",
            overflowWrap: "break-word"
          }}
        >
          <Linkify>{recipe.notes}</Linkify>
        </pre>
      )}
    </Sidebar>
  );
};
