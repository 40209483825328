import {
  CookbookAccessLevel,
  RecipeNameFragment,
  useMeQuery
} from "@saffron/controllers";
import * as React from "react";
import { useRef } from "react";
import { BottomButton } from "../../../ui/BottomButton";
import { pushLocation } from "../../../utils/pushLocation";
import { UpgradeModal } from "../../shared/UpgradeModal";
import { RecipeList } from "./CookbookSidebar";
import { IdStuff } from "./types";
import { RecipeItem } from "./ui/RecipeItem";
import { SortRecipesBySelect } from "./SortRecipesBySelect";

interface Props extends IdStuff {
  recipes: RecipeNameFragment[];
  isCookbookOwner: boolean;
  loading: boolean;
  cookbookAccessLevel: CookbookAccessLevel;
}

export const BrowseRecipes: React.FC<Props> = ({
  ids,
  setIds,
  recipes,
  cookbookAccessLevel
}) => {
  const { data } = useMeQuery();
  const showButton =
    ids.currentCookbookId &&
    ids.currentSectionId &&
    (cookbookAccessLevel === CookbookAccessLevel.Owner ||
      cookbookAccessLevel === CookbookAccessLevel.Editor);

  const prevRecipe = useRef<RecipeNameFragment | null>(null);
  const nextRecipe = useRef<RecipeNameFragment | null>(null);

  const currentIdx = recipes.findIndex(x => x.id === ids.currentRecipeId);

  if (currentIdx !== -1) {
    prevRecipe.current = recipes[currentIdx - 1];
    nextRecipe.current = recipes[currentIdx + 1];
  }

  React.useEffect(() => {
    const fn = (e: any) => {
      if (e.code === "ArrowUp") {
        e.preventDefault();
        if (prevRecipe.current) {
          setIds(state => ({
            ...state,
            currentRecipeId: prevRecipe.current?.id,
            slug: prevRecipe.current?.name
          }));
        }
      } else if (e.code === "ArrowDown") {
        e.preventDefault();
        if (nextRecipe.current) {
          setIds(state => ({
            ...state,
            currentRecipeId: nextRecipe.current?.id,
            slug: nextRecipe.current?.name
          }));
        }
      }
    };
    window.addEventListener("keydown", fn);

    return () => {
      window.removeEventListener("keydown", fn);
    };
  }, [setIds]);

  return (
    <div
      style={{
        flex: 3,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <RecipeList>
        {recipes.length ? <SortRecipesBySelect /> : null}
        {recipes.map(recipe => {
          return (
            <RecipeItem
              sectionId={ids.currentSectionId}
              key={recipe.id}
              onRecipeClick={r =>
                setIds({ ...ids, currentRecipeId: r.id, slug: r.name })
              }
              selected={recipe.id === ids.currentRecipeId}
              recipe={recipe}
              enableDrag={
                cookbookAccessLevel === CookbookAccessLevel.Owner ||
                cookbookAccessLevel === CookbookAccessLevel.Editor
              }
            />
          );
        })}
        {showButton && (
          <UpgradeModal title="Recipe Limit Reached">
            {setUpgradeModalOpen => (
              <BottomButton
                onClick={() => {
                  if (
                    data &&
                    data.me.user &&
                    data.me.user.numRecipes >= data.me.user.role.recipeLimit
                  ) {
                    setUpgradeModalOpen(true);
                  } else {
                    pushLocation(
                      `/new-recipe/cookbook/${ids.currentCookbookId}/${ids.currentSectionId}`
                    );
                  }
                }}
              >
                Add Recipe
              </BottomButton>
            )}
          </UpgradeModal>
        )}
      </RecipeList>
    </div>
  );
};
