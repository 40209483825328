import * as React from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { theme } from "../../../ui/theme";
import { reorder } from "../../../utils/reorder";
import { SectionItem } from "./ui/SectionItem";
import { BottomButton } from "../../../ui/BottomButton";
import {
  useUpdateSectionPositionsMutation,
  JustSectionFragment,
  useCreateSectionMutation,
  createSectionMutationOptions,
  updateSectionPositionsMutationOptions,
  CookbookAccessLevel,
  useMeQuery,
  toChildAccess,
  CookbooksQuery
} from "@saffron/controllers";
import { LineModalForm } from "../../../ui/LineModalForm";
import {
  validSectionText,
  SECTIONS_PER_COOKBOOK_LIMIT,
  sectionLimitErrMsg
} from "@saffron/common";
import { IdStuff } from "./types";

interface Props extends IdStuff {
  cookbook: CookbooksQuery["cookbooks"][0];
  sections: JustSectionFragment[];
}

export const SectionList = styled("div")`
  overflow-y: auto;
  flex: 1;
  color: #333;
  font-weight: 400;
  line-height: 1.2;
`;

export const BrowseSections: React.FC<Props> = ({
  ids,
  setIds,
  sections,
  cookbook
  // numRecipes,
  // loading
}) => {
  const [updateSectionPositions] = useUpdateSectionPositionsMutation();
  const [addSection] = useCreateSectionMutation();
  const [sectionModalOpen, setSectionModalOpen] = React.useState(false);
  const { data } = useMeQuery();

  // React.useEffect(() => {
  //   if (!loading && !sections.length && isCookbookOwner && numRecipes === 0) {
  //     setSectionModalOpen(true);
  //   }
  // }, [sections.length, isCookbookOwner, numRecipes, loading]);

  const showButton =
    ids.currentCookbookId &&
    (cookbook.accessLevel === CookbookAccessLevel.Owner ||
      cookbook.accessLevel === CookbookAccessLevel.Editor);

  return (
    <>
      <LineModalForm
        open={sectionModalOpen}
        label="section name"
        title="Add Section"
        submit={async text => {
          const response = await addSection(
            createSectionMutationOptions({
              input: {
                name: text,
                position: sections.length
                  ? sections[sections.length - 1].position + 1
                  : 0,
                cookbookId: ids.currentCookbookId!
              }
            })
          );
          if (
            response &&
            response.data &&
            response.data.createSection.section
          ) {
            setIds({
              ...ids,
              currentSectionId: response.data.createSection.section.id,
              currentRecipeId: undefined
            });
          }
        }}
        closeModal={() => setSectionModalOpen(false)}
        validationSchema={validSectionText}
      />
      <div
        style={{
          flex: 2,
          display: "flex",
          flexDirection: "column",
          borderRight: theme.border
        }}
      >
        <DragDropContext
          // onDragStart={({ source }) => {
          //   setIds({ ...ids, currentSectionId: sections[source.index].id });
          // }}
          onDragEnd={async (result: DropResult) => {
            if (!result.destination) {
              return;
            }

            if (result.destination.index === result.source.index) {
              return;
            }

            await updateSectionPositions(
              updateSectionPositionsMutationOptions({
                ids: reorder(
                  sections.map(x => x.id),
                  result.source.index,
                  result.destination!.index
                )
              })
            );
          }}
        >
          <Droppable droppableId="sections-droppable">
            {dropProvided => (
              <SectionList ref={dropProvided.innerRef}>
                {sections.map((section, i) => {
                  return (
                    <SectionItem
                      cookbook={cookbook}
                      key={section.id}
                      onSectionClick={id => {
                        if (id !== ids.currentSectionId) {
                          setIds({
                            ...ids,
                            currentSectionId: id,
                            currentRecipeId: undefined
                          });
                        }
                      }}
                      onDelete={() =>
                        setIds({
                          currentCookbookId: ids.currentCookbookId
                        })
                      }
                      selected={section.id === ids.currentSectionId}
                      section={section}
                      index={i}
                      sectionAccessLevel={toChildAccess(
                        section.owner,
                        data?.me.user?.id,
                        cookbook.accessLevel
                      )}
                      enableDrag
                    />
                  );
                })}
                {dropProvided.placeholder}
                {showButton ? (
                  <BottomButton
                    onClick={() => {
                      if (sections.length >= SECTIONS_PER_COOKBOOK_LIMIT) {
                        window.alert(sectionLimitErrMsg);
                      } else {
                        setSectionModalOpen(true);
                      }
                    }}
                  >
                    Add Section
                  </BottomButton>
                ) : null}
              </SectionList>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};
