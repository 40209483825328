import { NetworkStatus } from "@apollo/client";
import {
  CookbookAccessLevel,
  CookbooksQuery,
  JustSectionFragment,
  RecipeNameFragment,
  sortRecipesByField,
  useRecipeListSortBy,
  useRecipesByCookbookAndSectionIdQuery
} from "@saffron/controllers";
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ScrollableFlex } from "../../../ui/Flex";
import { Sidebar } from "../../../ui/Sidebar";
import { BrowseCookbooks } from "./BrowseCookbooks";
import { BrowseRecipes } from "./BrowseRecipes";
import { BrowseSections } from "./BrowseSections";
import { CookbookSquare } from "./CookbookSquare";
import { IdStuff } from "./types";

interface Props extends IdStuff {
  cookbooks: CookbooksQuery["cookbooks"];
  sections: JustSectionFragment[];
  currentCookbook: CookbooksQuery["cookbooks"][0] | undefined;
  setCookbookOrder: (value: Record<string, number>) => void;
  cbLoading: boolean;
  sectionsLoading: boolean;
}

export const RecipeList = styled("div")`
  overflow-y: auto;
  flex: 1;
  color: #0d0d0d;
  font-weight: 400;
`;
export const LS_COOKBOOK_ORDER = "cookbookOrder";

export const CookbookSidebar: React.FC<Props> = ({
  ids,
  setIds,
  cbLoading,
  cookbooks,
  currentCookbook,
  setCookbookOrder,
  sections,
  sectionsLoading
}) => {
  const { fieldToSortBy } = useRecipeListSortBy();

  const {
    data: recipesData,
    networkStatus: recipeNetworkStatus
  } = useRecipesByCookbookAndSectionIdQuery({
    variables: {
      sectionId: ids.currentSectionId!
    },
    skip: !ids.currentCookbookId || !ids.currentSectionId,
    notifyOnNetworkStatusChange: true
  });

  const [cookbookListShowing, setCookbookListShowing] = useState(false);
  useEffect(() => {
    if (!cbLoading && cookbooks.length === 0) {
      setCookbookListShowing(true);
    }
  }, [cbLoading, cookbooks.length]);

  let recipes: RecipeNameFragment[] = [];

  if (recipesData && recipesData.recipesByCookbookAndSectionId) {
    recipes = sortRecipesByField(
      recipesData.recipesByCookbookAndSectionId,
      fieldToSortBy
    );
  }

  if (recipeNetworkStatus === NetworkStatus.setVariables) {
    recipes = [];
  }

  let isCookbookOwner =
    currentCookbook?.accessLevel === CookbookAccessLevel.Owner;

  const firstCookbookId = cookbooks[0] ? cookbooks[0].id : "";
  const firstSectionId = sections[0] ? sections[0].id : "";
  const firstRecipeId = recipes[0] ? recipes[0].id : "";
  const firstRecipeName = recipes[0] ? recipes[0].name : "";
  const isCurrentSectionInCurrentCookbook = !!sections.find(
    x => x.id === ids.currentSectionId
  );
  const isCurrentRecipeInCurrentSection = !!recipes.find(
    x => x.id === ids.currentRecipeId
  );
  const isCurrentCookbookInCookbooks = !!cookbooks.find(
    x => x.id === ids.currentCookbookId
  );

  useEffect(() => {
    if (!ids.currentCookbookId && firstCookbookId && firstCookbookId !== "-1") {
      setIds({
        currentCookbookId: firstCookbookId
      });
    }

    if (!ids.currentSectionId && firstSectionId && firstSectionId !== "-1") {
      setIds({
        ...ids,
        currentSectionId: firstSectionId,
        currentRecipeId: undefined
      });
    }

    if (!ids.currentRecipeId && firstRecipeId && firstRecipeId !== "-1") {
      setIds({
        ...ids,
        currentRecipeId: firstRecipeId,
        slug: firstRecipeName
      });
    }
  }, [
    ids,
    firstCookbookId,
    firstSectionId,
    firstRecipeId,
    firstRecipeName,
    setIds
  ]);

  const recipesLoading =
    recipeNetworkStatus === NetworkStatus.setVariables ||
    recipeNetworkStatus === NetworkStatus.loading;

  const anyLoading = cbLoading || sectionsLoading || recipesLoading;

  useEffect(() => {
    if (anyLoading) {
      return;
    }

    if (!isCurrentCookbookInCookbooks && ids.currentCookbookId) {
      setIds({
        currentCookbookId: undefined
      });
    }

    if (!isCurrentRecipeInCurrentSection && ids.currentRecipeId) {
      setIds({
        ...ids,
        currentRecipeId:
          firstRecipeId === "-1" || !firstRecipeId ? undefined : firstRecipeId,
        slug: firstRecipeName
      });
    }

    if (!isCurrentSectionInCurrentCookbook && ids.currentSectionId) {
      setIds({
        ...ids,
        currentSectionId:
          firstSectionId === "-1" || !firstSectionId
            ? undefined
            : firstSectionId
      });
    }
  }, [
    isCurrentCookbookInCookbooks,
    isCurrentRecipeInCurrentSection,
    ids,
    setIds,
    firstRecipeId,
    firstRecipeName,
    isCurrentSectionInCurrentCookbook,
    anyLoading,
    firstSectionId
  ]);

  return (
    <Sidebar>
      <CookbookSquare
        ids={ids}
        setIds={setIds}
        cookbookListShowing={cookbookListShowing}
        currentCookbook={currentCookbook}
        isCookbookOwner={isCookbookOwner}
        setCookbookListShowing={setCookbookListShowing}
      />

      {cookbookListShowing ? (
        <BrowseCookbooks
          ids={ids}
          setIds={setIds}
          cookbooks={cookbooks}
          setCookbookListShowing={setCookbookListShowing}
          setCookbookOrder={setCookbookOrder}
          cbLoading={cbLoading}
        />
      ) : (
        <ScrollableFlex flex={1} fontFamily="ui">
          {currentCookbook ? (
            <>
              <BrowseSections
                ids={ids}
                setIds={setIds}
                sections={sections}
                cookbook={currentCookbook}
              />
              <BrowseRecipes
                cookbookAccessLevel={
                  currentCookbook?.accessLevel || CookbookAccessLevel.Viewer
                }
                ids={ids}
                setIds={setIds}
                isCookbookOwner={isCookbookOwner}
                recipes={recipes}
                loading={recipesLoading}
              />
            </>
          ) : null}
        </ScrollableFlex>
      )}
    </Sidebar>
  );
};
