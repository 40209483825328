import * as React from "react";
import { JustCookbookFragment } from "@saffron/controllers";
import { GrungePattern } from "./CookbookPatterns/GrungePattern";
import { ZiggyPattern } from "./CookbookPatterns/ZiggyPattern";
import { theme } from "./theme";
import { percentToHex } from "@saffron/common";
import { coverDetailsToFontColor } from "../utils/coverDetailsToFontColor";
// import { SpiceAndRiceCover } from "./SpiceAndRiceCover";

interface Props {
  cookbook: JustCookbookFragment;
}

export const COOKBOOK_COVER_HEIGHT = 150;
export const COOKBOOK_COVER_WIDTH = 110;
const borderWidth = 6;

export const SmallCookbookCover: React.FC<Props> = ({ cookbook, children }) => {
  // if (cookbook.id === "ea853cdd-8082-4fa5-a69e-7e6a9f196348") {
  //   return <SpiceAndRiceCover />;
  // }

  let Pattern =
    cookbook.coverDetails.svgPatternKey === "grunge" ? (
      <GrungePattern height="100%" width="100%" {...cookbook.coverDetails} />
    ) : (
      <ZiggyPattern height="100%" width="100%" {...cookbook.coverDetails} />
    );

  if (cookbook.pictureUrl) {
    Pattern = (
      <img
        style={{
          width: 110,
          height: 150,
          objectFit: "cover",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }}
        src={cookbook.pictureUrl}
        alt={cookbook.name}
      />
    );
  }

  const cookbookText =
    cookbook.name.slice(0, 50) + (cookbook.name.length > 50 ? "..." : "");

  const longestWordLength = cookbook.name
    .split(" ")
    .reduce((pv, cv) => Math.max(cv.length, pv), 0);
  const lineWidth = 15;
  let fontSize = 18;
  if (longestWordLength >= 10) {
    fontSize = 12;
  } else if (longestWordLength >= 8) {
    fontSize = 14;
  } else if (longestWordLength >= 6) {
    fontSize = 16;
  }

  const lineHeight = `${fontSize + 2}px`;
  const top = cookbook.coverDetails.bottomText ? undefined : "6%";
  const bottom = cookbook.coverDetails.bottomText ? 10 : undefined;
  const color = coverDetailsToFontColor(cookbook.coverDetails);

  const acOpacityInt = cookbook.coverDetails.acOpacity
    ? parseInt(cookbook.coverDetails.acOpacity)
    : NaN;
  const hexOpacity = isNaN(acOpacityInt) ? "" : percentToHex(acOpacityInt);

  return (
    <div
      style={{
        backgroundColor: cookbook.coverDetails.bgColor || undefined,
        height: COOKBOOK_COVER_HEIGHT,
        width: COOKBOOK_COVER_WIDTH,
        position: "relative",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        ...(cookbook.coverDetails.coverStyleKey === "outline"
          ? {
              borderColor: cookbook.coverDetails.ac + hexOpacity,
              borderWidth,
              borderStyle: "solid"
            }
          : {})
      }}
    >
      <div
        style={
          cookbook.coverDetails.coverStyleKey === "outline"
            ? {
                height: COOKBOOK_COVER_HEIGHT - borderWidth * 2,
                width: COOKBOOK_COVER_WIDTH - borderWidth * 2,
                overflow: "hidden"
              }
            : {}
        }
      >
        {children ? children : Pattern}
      </div>
      {cookbook.coverDetails.coverStyleKey === "line" ? (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
            height: "100%",
            width: lineWidth,
            backgroundColor: cookbook.coverDetails.ac + hexOpacity
          }}
        />
      ) : null}
      {cookbook.coverDetails.coverStyleKey === "stripe" ? (
        <div
          style={{
            position: "absolute",
            top,
            bottom,
            width: "100%",
            padding: "4px 4px",
            borderTopWidth: 2,
            borderBottomWidth: 2,
            borderTopStyle: "solid",
            borderBottomStyle: "solid",
            borderColor: "rgba(255, 255, 255, .3)",
            backgroundColor: cookbook.coverDetails.ac + hexOpacity,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontFamily: theme.fonts.secondary,
              textTransform: "uppercase",
              fontWeight: 600,
              letterSpacing: 1,
              color,
              lineHeight,
              fontSize
            }}
          >
            {cookbookText}
          </div>
        </div>
      ) : null}
      {cookbook.coverDetails.coverStyleKey === "line" ? (
        <>
          {cookbook.pictureUrl ? (
            <div
              style={{
                background: cookbook.coverDetails.bottomText
                  ? "linear-gradient(#ffffff00,#f2f2f2)"
                  : "linear-gradient(#f2f2f2,#ffffff00)",
                position: "absolute",
                left: 0,
                right: 0,
                [cookbook.coverDetails.bottomText ? "bottom" : "top"]: 0,
                height: 80
              }}
            />
          ) : null}
          <div
            style={{
              position: "absolute",
              top,
              bottom,
              width: "100%",
              textAlign: "center",
              fontFamily: theme.fonts.secondary,
              fontWeight: 800,
              color,
              fontSize,
              paddingLeft: lineWidth + 8,
              paddingRight: 8,
              zIndex: 1,
              lineHeight
            }}
          >
            {cookbookText}
          </div>
        </>
      ) : null}
      {cookbook.coverDetails.coverStyleKey === "outline" ? (
        <div
          style={{
            position: "absolute",
            [cookbook.coverDetails.bottomText ? "bottom" : "top"]: 0,
            display: "flex",
            width: "100%",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              backgroundColor: cookbook.coverDetails.ac + hexOpacity,
              textAlign: "center",
              bottom: cookbook.coverDetails.bottomText ? 0 : undefined,
              fontFamily: theme.fonts.primary,
              fontStyle: "italic",
              width: "80%",
              color,
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: cookbook.coverDetails.bottomText ? 3 : 0,
              paddingBottom: !cookbook.coverDetails.bottomText ? 5 : 0,
              lineHeight,
              fontSize
            }}
          >
            {cookbookText}
          </div>
        </div>
      ) : null}
    </div>
  );
};
