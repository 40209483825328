import { useMeQuery } from "@saffron/controllers";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { skinnySideBarWidth } from "../../modules/menuPlanner/shared/constants";
import { DivButton } from "../DivButton";
import { Flex } from "../Flex";
import Icon from "../Icon";
import { PotLogo } from "../Logo";
import { patternWidth, theme } from "../theme";
import { media } from "../mediaTemplates";
import { abbreviateNumber } from "@saffron/common";
import { UpgradeModal } from "../../modules/shared/UpgradeModal";

interface Props {
  width?: number;
  backgroundColor?: string;
  noBorderBottom?: boolean;
  noRecipeCount?: boolean;
  noBorderRight?: boolean;
  tightTop?: boolean;
  fullscreen?: boolean;
}

// export const bigSidebar = 463;
export const mediumSidebar = 363;

const Container = styled("div")<{ backgroundColor?: string }>`
  z-index: 4;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.backgroundColor || "#fafafa"};
  position: fixed;
  top: 0px;
  left: ${patternWidth};
  height: 100%;
  border-right: 3px solid rgb(221, 227, 227);
  width: ${skinnySideBarWidth}px;
  ${media.oneColSteps`
  width: ${mediumSidebar}px;
        `}
`;

export const LS_NEWS_BANNER = "@saffron-news-banner";

export const Sidebar: React.FC<Props> = ({
  backgroundColor,
  width,
  children,
  noBorderBottom,
  noRecipeCount,
  noBorderRight,
  tightTop,
  fullscreen
}) => {
  const { data, loading } = useMeQuery();
  const [showBanner, setShowBanner] = useState(false);

  let createdAt = data && data.me && data.me.user && data.me.user.createdAt;

  useEffect(() => {
    if (!createdAt) {
      return;
    }

    // only show banner for people you signed up for
    // saffron before September 10, 2019
    if (new Date(createdAt) > new Date(2019, 8, 10)) {
      return;
    }

    try {
      const x = localStorage.getItem(LS_NEWS_BANNER);
      if (!x) {
        setShowBanner(true);
      }
    } catch {}
  }, [createdAt]);
  const reachedLimit =
    !noRecipeCount &&
    data &&
    data.me.user &&
    data.me.user.numRecipes >= data.me.user.role.recipeLimit;

  let body: any = null;

  const shrinkLogo = skinnySideBarWidth === width;

  if (showBanner && false) {
    body = (
      <div
        style={{
          backgroundColor: "#C6A15B",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center"
        }}
      >
        <a
          href="https://youtu.be/Cqkc6RMCOmw"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontFamily: theme.fonts.ui,
            fontSize: "0.875rem",
            flex: 1,
            paddingLeft: 16,
            paddingRight: 16
          }}
        >
          <span style={{ fontWeight: 600 }}>NEW RELEASE: </span>
          Click to watch a video on the new updates.
        </a>
        <div style={{ paddingRight: ".5em" }}>
          <Icon
            onClick={() => {
              try {
                localStorage.setItem(LS_NEWS_BANNER, "false");
              } catch {}

              setShowBanner(false);
            }}
            width="2em"
            height="2em"
            fill="rgba(255, 255, 255, 0.4)"
            name="x"
          />
        </div>
      </div>
    );
  } else {
    const dontShowRecipeCount =
      noRecipeCount || loading || !data || !data.me.user || reachedLimit;
    body = (
      <>
        {!reachedLimit || noRecipeCount ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: tightTop ? 0 : 13,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <PotLogo
              width={shrinkLogo ? "95px" : "112.5px"}
              height={shrinkLogo ? "38px" : "45px"}
            />
            {dontShowRecipeCount ? null : (
              <div
                style={{
                  marginTop: 5,
                  fontFamily: theme.fonts.ui,
                  color: "#444",
                  fontSize: "0.75rem",
                  marginBottom: 13,
                  textAlign: "center"
                }}
              >
                <span>{data?.me.user?.numRecipes.toLocaleString()}</span>{" "}
                <span style={{ color: theme.colors.darkGold }}>/</span>{" "}
                <span>
                  {abbreviateNumber(data?.me.user?.role.recipeLimit || 0)}
                </span>
                <span
                  style={{
                    marginLeft: 3,
                    letterSpacing: ".9px"
                  }}
                >
                  RECIPES
                </span>
              </div>
            )}
          </div>
        ) : (
          <UpgradeModal>
            {openUpgradeModal => (
              <div
                style={{
                  fontFamily: theme.fonts.ui,
                  color: "#444",
                  fontSize: "1.0rem"
                }}
              >
                {data?.me.user?.role.recipeLimit.toLocaleString()} Recipes
                Reached{" "}
                <DivButton
                  style={{
                    color: theme.colors.darkGold,
                    fontSize: "1.0rem"
                  }}
                  onClick={() => {
                    if (!data || !data.me.user) {
                      return;
                    }

                    openUpgradeModal(true);
                  }}
                >
                  Upgrade Now
                </DivButton>
              </div>
            )}
          </UpgradeModal>
        )}
      </>
    );
  }

  return (
    <Container
      style={{
        width: fullscreen ? "100%" : width,
        left: fullscreen ? 0 : undefined,
        borderRight: noBorderRight ? "none" : undefined
      }}
      backgroundColor={backgroundColor}
    >
      <Flex
        borderBottom={
          noBorderBottom ? undefined : "1px solid rgb(221, 227, 227)"
        }
        justifyContent="center"
        alignItems="center"
        width="100%"
        style={{
          backgroundColor: reachedLimit ? "#fff" : undefined,
          minHeight: noRecipeCount || reachedLimit ? 50 : undefined
        }}
      >
        {body}
      </Flex>
      {children}
    </Container>
  );
};
