import {
  cookbookLimitErrMsg,
  COOKBOOK_LIMIT,
  validCookbookText
} from "@saffron/common";
import {
  CookbookAccessLevel,
  CookbooksQuery,
  createCookbookMutationOptions,
  deleteCookbookMutationOptions,
  removeCookbookFromCollectionMutationOptions,
  updateCookbookMutationOptions,
  useCreateCookbookMutation,
  useDeleteCookbookMutation,
  useRemoveCookbookFromCollectionMutation,
  useUpdateCookbookMutation
} from "@saffron/controllers";
import * as React from "react";
import { BottomButton } from "../../../ui/BottomButton";
import { ScrollableFlex } from "../../../ui/Flex";
import { LineModalForm } from "../../../ui/LineModalForm";
import { Modal } from "../../../ui/Modal";
import { theme } from "../../../ui/theme";
import { ShareCookbookModal } from "../../ricons/ShareRicon/ShareCookbookModal";
import { IdStuff } from "./types";
import { CookbookCoverCell } from "./ui/CookbookCoverCell";

interface Props extends IdStuff {
  setCookbookOrder: (x: Record<string, number>) => void;
  setCookbookListShowing: (x: boolean) => void;
  cookbooks: CookbooksQuery["cookbooks"];
  cbLoading: boolean;
}

type Cb = CookbooksQuery["cookbooks"][0];

export const BrowseCookbooks: React.FC<Props> = ({
  setIds,
  setCookbookListShowing,
  cookbooks,
  cbLoading,
  setCookbookOrder
}) => {
  const [updateCookbook] = useUpdateCookbookMutation();
  const [
    deleteCookbook,
    { loading: deleteCookbookLoading }
  ] = useDeleteCookbookMutation();
  const [
    removeCookbookFromCollection,
    { loading: removeCookbookFromCollectionLoading }
  ] = useRemoveCookbookFromCollectionMutation();
  const [addCookbook] = useCreateCookbookMutation();
  const [cookbookModalOpen, setCookbookModalOpen] = React.useState(false);
  const [
    cookbookDeleteModalOpen,
    setCookbookDeleteModalOpen
  ] = React.useState<Cb | null>(null);
  const [
    sharedCookbookRemoveModalOpen,
    setSharedCookbookRemoveModalOpen
  ] = React.useState<Cb | null>(null);
  const [
    renameCookbookModalOpen,
    setRenameCookbookModalOpen
  ] = React.useState<Cb | null>(null);
  const [
    shareCookbookModalOpen,
    setShareCookbookModalOpen
  ] = React.useState<Cb | null>(null);

  let currentShareModalCb = null;

  if (shareCookbookModalOpen) {
    const cb = cookbooks.find(x => x.id === shareCookbookModalOpen.id);
    if (cb) {
      currentShareModalCb = cb;
    }
  }

  React.useEffect(() => {
    if (!cbLoading && cookbooks.length === 0) {
      setCookbookModalOpen(true);
    }
  }, [cbLoading, cookbooks.length]);

  return (
    <>
      <LineModalForm
        open={cookbookModalOpen}
        label="cookbook name"
        title="Add Cookbook"
        submit={async text => {
          const response = await addCookbook(
            createCookbookMutationOptions(
              {
                input: {
                  name: text
                }
              },
              cookbooks.find(x => x.accessLevel === CookbookAccessLevel.Owner)
                ?.byName || ""
            )
          );
          if (
            response &&
            response.data &&
            response.data.createCookbook.cookbook
          ) {
            setIds({
              currentCookbookId: response.data.createCookbook.cookbook.id
            });
            setCookbookListShowing(false);
          }
        }}
        closeModal={() => setCookbookModalOpen(false)}
        validationSchema={validCookbookText}
        skipReset
      />
      <ScrollableFlex
        style={{ flexDirection: "column" }}
        flex={1}
        fontFamily="ui"
      >
        <div style={{ flex: 1, overflowY: "auto" }}>
          {cookbooks.map(x => (
            <CookbookCoverCell
              key={x.id}
              onClick={() => {
                setIds({
                  currentCookbookId: x.id
                });
                const newCookbookOrder: Record<string, number> = {};
                cookbooks.forEach((c, i) => {
                  if (c.id === x.id) {
                    newCookbookOrder[c.id] = 0;
                  } else {
                    newCookbookOrder[c.id] = i + 1;
                  }
                });
                setCookbookOrder(newCookbookOrder);
                setCookbookListShowing(false);
              }}
              cookbook={x}
              setCookbookDeleteModalOpen={setCookbookDeleteModalOpen}
              setRenameCookbookModalOpen={setRenameCookbookModalOpen}
              setShareCookbookModalOpen={setShareCookbookModalOpen}
              setSharedCookbookRemoveModalOpen={
                setSharedCookbookRemoveModalOpen
              }
            />
          ))}
          <BottomButton
            onClick={() => {
              if (cookbooks.length >= COOKBOOK_LIMIT) {
                window.alert(cookbookLimitErrMsg);
              } else {
                setCookbookModalOpen(true);
              }
            }}
          >
            Add Cookbook
          </BottomButton>
        </div>
      </ScrollableFlex>
      <Modal
        isOpen={!!cookbookDeleteModalOpen}
        onRequestClose={() => setCookbookDeleteModalOpen(null)}
        title="Permanently Delete Cookbook"
        buttonVariant="red"
        secondaryButtonText="CANCEL"
        secondaryButtonClick={() => setCookbookDeleteModalOpen(null)}
        mainButtonText="DELETE"
        loading={deleteCookbookLoading}
        mainButtonClick={async () => {
          await deleteCookbook(
            deleteCookbookMutationOptions({ id: cookbookDeleteModalOpen!.id })
          );
          setCookbookDeleteModalOpen(null);
          setIds({});
        }}
      >
        <div style={{ fontFamily: theme.fonts.ui, color: "#444" }}>
          Deleting a cookbook cannot be undone and will permanently delete any
          of the sections and recipes inside of it.
        </div>
      </Modal>
      <Modal
        isOpen={!!sharedCookbookRemoveModalOpen}
        onRequestClose={() => setSharedCookbookRemoveModalOpen(null)}
        title="Remove Shared Cookbook"
        mainButtonText="REMOVE"
        loading={removeCookbookFromCollectionLoading}
        mainButtonClick={async () => {
          await removeCookbookFromCollection(
            removeCookbookFromCollectionMutationOptions({
              cookbookId: sharedCookbookRemoveModalOpen!.id
            })
          );
          setSharedCookbookRemoveModalOpen(null);
          setIds({});
        }}
      >
        <div style={{ fontFamily: theme.fonts.ui, color: "#444" }}>
          If you remove this shared cookbook from your account, you will no
          longer be able to see any if the recipes inside.
        </div>
      </Modal>
      {shareCookbookModalOpen && currentShareModalCb ? (
        <ShareCookbookModal
          open={!!shareCookbookModalOpen}
          toggleModal={() => setShareCookbookModalOpen(null)}
          cookbook={currentShareModalCb}
        />
      ) : null}
      <LineModalForm
        open={!!renameCookbookModalOpen}
        label="cookbook name"
        title="Rename Cookbook"
        submit={async text => {
          await updateCookbook(
            updateCookbookMutationOptions({
              id: renameCookbookModalOpen!.id,
              cookbook: {
                name: text
              }
            })
          );
        }}
        closeModal={() => setRenameCookbookModalOpen(null)}
        initialText={
          renameCookbookModalOpen ? renameCookbookModalOpen.name : undefined
        }
        validationSchema={validCookbookText}
      />
    </>
  );
};
