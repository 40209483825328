import { slugify } from "@saffron/common";
import {
  changeCookbookPrivacyMutationOptions,
  CookbookAccessLevel,
  CookbooksQuery,
  updateSharedCookbookUserMutationOptions,
  useChangeCookbookPrivacyMutation,
  useSharedCookbookUsersQuery,
  useUpdateSharedCookbookUserMutation
} from "@saffron/controllers";
import React from "react";
import { CopyInput } from "../../../ui/CopyInput";
import { Modal } from "../../../ui/Modal";
import { NativeSelect } from "../../../ui/Select";
import { theme } from "../../../ui/theme";

interface ShareCookbookModalProps {
  open: boolean;
  toggleModal: () => void;
  cookbook: CookbooksQuery["cookbooks"][0];
}

const accessOptions = [
  { label: "private", value: "private" },
  { label: "view only", value: "unlisted" },
  { label: "view and edit", value: "view-and-edit" }
];

const createUrl = (shareId: string, slug: string) =>
  `${window.location.origin.replace(
    "www.",
    "share."
  )}/a/cookbooks/${shareId}/${slug}`;

export const ShareCookbookModal: React.FC<ShareCookbookModalProps> = ({
  open,
  toggleModal,
  cookbook
}) => {
  const [changeCookbookPrivacy] = useChangeCookbookPrivacyMutation();
  const { data: sData } = useSharedCookbookUsersQuery({
    skip: cookbook.privacy === "private",
    variables: { cookbookId: cookbook.id },
    notifyOnNetworkStatusChange: true
  });
  const [updateSharedCookbookUser] = useUpdateSharedCookbookUserMutation();
  return (
    <Modal
      height={600}
      isOpen={open}
      onRequestClose={toggleModal}
      title="Share Cookbook"
    >
      <div>Set Access: </div>
      <NativeSelect
        style={{ marginTop: 8, width: "100%" }}
        value={cookbook.privacy}
        onChange={v => {
          if (v && v !== cookbook.privacy) {
            if (v === "private") {
              const y = window.confirm(
                "Are you sure you want to make this cookbook private, it will revoke access to anyone you shared it with?"
              );
              if (!y) {
                return;
              }
            }
            changeCookbookPrivacy(
              changeCookbookPrivacyMutationOptions({
                id: cookbook.id,
                input: {
                  privacy: v
                }
              })
            );
          }
        }}
        options={accessOptions.map(x => (
          <option key={x.value} value={x.value}>
            {x.label}
          </option>
        ))}
      />
      {cookbook.shareId &&
      (cookbook.privacy === "unlisted" ||
        cookbook.privacy === "view-and-edit") ? (
        <div style={{ width: "100%", display: "flex", marginTop: 20 }}>
          <CopyInput
            autoFocus
            text={createUrl(cookbook.shareId, slugify(cookbook.name))}
            buttonText="COPY LINK"
            variant="dark"
          />
        </div>
      ) : null}
      {!sData?.sharedCookbookUsers.users.length ? null : (
        <>
          <div
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "#DDE2E3",
              marginTop: 50
            }}
          />
          <div
            style={{
              fontSize: 22,
              fontFamily: theme.uiFontStack,
              fontWeight: 600,
              paddingTop: 24
            }}
          >
            Manage Permissions
          </div>
        </>
      )}
      <div style={{ overflowY: "auto", flex: 1, marginTop: "1em" }}>
        {sData?.sharedCookbookUsers.users.map(({ accessLevel, user }) => {
          return (
            <div style={{ display: "flex", marginBottom: 24 }} key={user.id}>
              <div
                style={{
                  flex: 1,
                  color: "#444",
                  fontFamily: theme.uiFontStack
                }}
              >
                {user.firstName} {user.lastName}
              </div>
              <div
                style={{
                  position: "relative",
                  paddingLeft: 16
                }}
              >
                <select
                  dir="rtl"
                  style={{
                    cursor: "pointer",
                    outline: "none",
                    flex: 0,
                    color: "#666666",
                    fontSize: 16,
                    borderRadius: 0,
                    appearance: "none",
                    border: "none",
                    fontFamily: theme.fonts.ui,
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    paddingRight: 24,
                    background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' height='.75em' width='.75em' fill='%23444'%3E%3Cpath d='M17.418,6.109c0.272-0.268,0.709-0.268,0.979,0c0.27,0.268,0.271,0.701,0,0.969l-7.908,7.83 c-0.27,0.268-0.707,0.268-0.979,0l-7.908-7.83c-0.27-0.268-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.979,0L10,13.25 L17.418,6.109z'%3E%3C/path%3E%3C/svg%3E")`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "95% 60%"
                  }}
                  value={accessLevel}
                  onChange={e => {
                    const v = e.target.value;
                    if (v && accessLevel !== v) {
                      updateSharedCookbookUser(
                        updateSharedCookbookUserMutationOptions({
                          input: {
                            cookbookId: cookbook.id,
                            permission: v,
                            userId: user.id
                          }
                        })
                      );
                    }
                  }}
                >
                  {[
                    {
                      label: "view and edit",
                      value: CookbookAccessLevel.Editor
                    },
                    { label: "view only", value: CookbookAccessLevel.Viewer },
                    { label: "revoke access", value: "revoke" }
                  ].map(({ label, value }) => (
                    <option dir="ltr" key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
