import {
  replaceAll,
  spacyFractionToText,
  IngredientFormValue,
  genId
} from "@saffron/common";
import { FormikProps } from "formik";
import { withHistory } from "slate-history";
import React, { useMemo, useState, useCallback, useRef } from "react";
import { createEditor, Node, Transforms } from "slate";
import {
  Editable,
  Slate,
  withReact,
  RenderElementProps,
  ReactEditor
} from "slate-react";
import { PrettyLink } from "../../../ui/PrettyLink";
import { theme } from "../../../ui/theme";
import { notificationState } from "../../misc/notifications/NotificationState";
import { EditorWrapper } from "../../recipe/shared/RecipeForm/ui/EditorComponents";
import { RecipeLinkModal } from "../RecipeLinkModal";
import {
  insertText,
  toggleBlock,
  removeBlockOnReturn,
  isBlockActive,
  withPasteBlocks
} from "../slateUtils";
import { Range } from "slate";
import { ConvertUnitsModal } from "../ConvertUnitsModal/ConvertUnitsModal";
import { valueToIngredients } from "../../recipe/shared/RecipeForm/Ingredients/valueToIngredients";
import { ingredientsToValue } from "../../recipe/shared/RecipeForm/ingredientsToValueJSON";
import { useParensConversion } from "../../../utils/useParensConversion";
import { getParenConversionOptions } from "@saffron/controllers";

interface Props {
  renderHeader: (data: any) => JSX.Element | null;
  field: {
    onChange: (e: React.ChangeEvent<any>) => void;
    onBlur: (e: any) => void;
    value: Node[];
    name: string;
  };
  form: FormikProps<any>;
  dispatch: (action: "key-press") => void;
  placeholder?: string;
}

const frontRegex = /^[^a-z\d.]*/i;

export const IngredientDraftField: React.FC<Props> = ({
  field: { name, value },
  form: { setFieldValue },
  renderHeader,
  dispatch,
  placeholder,
  ...props
}) => {
  const { modal, setModalOptions } = useParensConversion({
    onIngredients: igs => {
      setFieldValue(name, ingredientsToValue(igs));
    }
  });
  const [convertUnitsModalOpen, setConvertUnitsModalOpen] = useState<
    null | IngredientFormValue[]
  >(null);
  const editor = useMemo(
    () => withHistory(withPasteBlocks(withReact(createEditor()))),
    []
  );
  const [recipeLinkModalOpen, setRecipeLinkModalOpen] = useState(false);
  const selection = useRef<Range | null>(null);

  if (editor.selection) {
    selection.current = editor.selection;
  }

  const renderElement = useCallback(
    ({ attributes, children, element: { type } }: RenderElementProps) => {
      switch (type) {
        case "header-three":
          return (
            <h3 className="draftjs-ingredient-header" {...attributes}>
              {children}
            </h3>
          );
        case "recipe-link":
          return (
            <div style={{ paddingBottom: "1em" }} {...attributes}>
              <PrettyLink>{children}</PrettyLink>
            </div>
          );
        case "optional-text":
          return (
            <div
              style={{
                paddingBottom: "1em",
                fontFamily: theme.fonts.secondary,
                color: "#70A3B7"
              }}
              {...attributes}
            >
              {children}
            </div>
          );
        default:
          // paragraph
          return (
            <div
              style={{
                paddingBottom: "1em",
                fontFamily: theme.fonts.secondary
              }}
              {...attributes}
            >
              {children}
            </div>
          );
      }
    },
    []
  );

  const onKeyDown = useCallback(
    e => {
      removeBlockOnReturn(e, editor);
    },
    [editor]
  );

  return (
    <React.Fragment>
      {modal}
      {renderHeader({
        toggleConvertUnits: (e: any) => {
          e.preventDefault();
          const igs = valueToIngredients(value, true);
          const options = getParenConversionOptions(igs);
          if (options) {
            setModalOptions(options);
          } else {
            setConvertUnitsModalOpen(igs);
          }
        },
        toggleHeader: (e: any) => {
          e.preventDefault();
          toggleBlock(editor, "header-three");
        },
        toggleRecipeLink: (e: any) => {
          e.preventDefault();

          const isActive = isBlockActive(editor, "recipe-link");

          if (isActive) {
            Transforms.setNodes(editor, {
              type: "paragraph"
            });
            return;
          }

          setRecipeLinkModalOpen(true);
        },
        insertText: (text: string) =>
          insertText(text, editor, selection.current),
        formatIngredients: () => {
          const newNodes: Node[] = [];
          if (value.length === 1) {
            newNodes.push(value[0]);
          } else if (value.length === 0) {
            return;
          } else {
            value.forEach(node => {
              if (!Node.string(node)) {
                return;
              }
              if (node.type === "paragraph") {
                newNodes.push({
                  data: node.data,
                  type: Node.string(node).endsWith(":")
                    ? "header-three"
                    : "paragraph",
                  children: [
                    {
                      text: replaceAll(Node.string(node), spacyFractionToText)
                        .replace(/\s\s+/g, " ")
                        .replace(frontRegex, "")
                    }
                  ]
                });
              } else {
                newNodes.push(node);
              }
            });
          }
          notificationState.send({
            variant: "success",
            text: "You're good to go!",
            icon: "check"
          });
          setFieldValue(name, newNodes);
        }
      })}
      <EditorWrapper>
        <Slate
          editor={editor}
          value={value}
          onChange={x => {
            setFieldValue(name, x);
          }}
          {...props}
        >
          <Editable
            placeholder={placeholder}
            autoCorrect={"off"} // disable mac text replace, doesn't work well with slate
            autoFocus
            onKeyDown={onKeyDown}
            renderElement={renderElement}
          />
        </Slate>
      </EditorWrapper>
      {convertUnitsModalOpen ? (
        <ConvertUnitsModal
          isOpen={!!convertUnitsModalOpen}
          onRequestClose={() => setConvertUnitsModalOpen(null)}
          onConvertedIngredients={igs => {
            setFieldValue(name, ingredientsToValue(igs));
            setConvertUnitsModalOpen(null);
          }}
          ingredients={convertUnitsModalOpen}
          tmpIdMap={value.reduce((pv, _, i) => {
            pv[genId()] = i;
            return pv;
          }, {} as Record<string, number>)}
        />
      ) : null}
      <RecipeLinkModal
        closeModal={() => setRecipeLinkModalOpen(false)}
        open={recipeLinkModalOpen}
        onRecipeClick={async ({ id }) => {
          setRecipeLinkModalOpen(false);

          Transforms.setNodes(
            editor,
            {
              type: "recipe-link",
              data: { recipeId: id }
            },
            {
              at: selection.current || undefined
            }
          );
          ReactEditor.focus(editor);
        }}
      />
    </React.Fragment>
  );
};
