import React from "react";
import startOfWeek from "date-fns/startOfWeek";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import { Dayjs } from "dayjs";
import { theme } from "../../../../ui/theme";
import styled from "styled-components";

interface Props {
  validStartOfWeek: number;
  date: Dayjs;
}

const Grid = styled("div")({
  backgroundColor: "#666",
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  padding: "4px 0px"
});

export const DaysHeader: React.FC<Props> = ({ date, validStartOfWeek }) => {
  let startingDate = startOfWeek(date.toDate(), {
    weekStartsOn: validStartOfWeek as any
  });

  return (
    <>
      <Grid>
        {[0, 1, 2, 3, 4, 5, 6].map(() => {
          const day = format(startingDate, "iii");
          startingDate = addDays(startingDate, 1);
          return (
            <div
              key={day}
              style={{
                color: "#fff",
                fontFamily: theme.fonts.ui,
                backgroundColor: "#666",
                fontSize: "1.125rem",
                marginLeft: 14
              }}
            >
              {day.toUpperCase()}
            </div>
          );
        })}
      </Grid>
    </>
  );
};
