import React from "react";
import styled, { css } from "styled-components";
import { queries } from "../../ui/globalStyles";
import { theme } from "../theme";
import { media } from "../mediaTemplates";

export const Grid = styled("div")`
  display: grid;
  grid-template-areas: "author" "times" "ingredients" "instructions";
  grid-template-columns: 100%;
  grid-row-gap: 1.953em;
  ${media.oneColSteps`
  grid-template-areas: "author author" "times times" "ingredients instructions";
  grid-template-columns: 30% 70%;
  grid-row-gap: 1.953em;
  `};
  ${media.dropTime`
  grid-template-areas: "author times" "ingredients instructions";
  grid-template-columns: 30% 70%;
  grid-row-gap: 3.125em;
  `};
`;

export const AuthorSection = styled("div")`
  grid-area: author;
  padding-top: 0.72em;
  ${media.oneColSteps`
  padding-top: 0px;
  `};
`;

// !important is for printing view //
export const TimeSection = styled("div")`
  grid-area: times;
  align-items: flex-start;
  display: flex !important;
  max-width: 100%;
  padding-left: 0;
  ${media.oneColSteps`
    padding-left: 0;
  `};
  ${media.dropTime`
    padding-left: 3.615em;
  `};
`;

export const NotesTimeContainer = styled("div")`
  margin-left: auto;
  display: none;
  ${media.dropTime`
  display: block;
  `};
`;

export const NotesServingsContainer = styled("div")`
  margin-left: auto;
  display: block;
  ${media.dropTime`
    display: none;
  `};
`;

export const IngredientSection = styled("div")`
  margin-top: 1px;
  position: relative;
  grid-area: ingredients;
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 1em;
  line-height: 1.35;
  ${queries.smallLaptop`
    height: auto;
  `};
  &:first-child {
    padding-top: 0 !important;
  }
`;

export const instructionPaddingBottomClassname = css`
  font-family: ${theme.fonts.secondary};
  padding-bottom: 8px;
`;

export const UlInstructions = styled("ul")`
  list-style: none;
  grid-area: instructions;
  font-family: ${theme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  margin-left: 0;
  margin-bottom: 0.64em;
  line-height: 1.563;
  margin-top: 0;
  padding: 0px;
  max-width: 525px;
  li {
    ${instructionPaddingBottomClassname};
  }
  ${media.oneColSteps`
    margin-left: 2.74em;
  padding: 0 0 0 0.875em;
  `};
`;

const OlInstructions = UlInstructions.withComponent("ol");

export const InstructionSection = ({
  ul,
  children
}: {
  ul: boolean;
  children: React.ReactNode;
}) =>
  ul ? (
    <UlInstructions data-testid="recipe-instruction-section">
      {children}
    </UlInstructions>
  ) : (
    <OlInstructions data-testid="recipe-instruction-section">
      {children}
    </OlInstructions>
  );

export const Name = styled("h1")<{ descriptionBelow: boolean }>`
  font-family: ${theme.primaryFontStack};
  text-transform: uppercase;
  padding-bottom .25em;
  margin-bottom: ${p => (p.descriptionBelow ? "0" : ".5em")};
  border-bottom: ${theme.border};
`;

export const Description = styled("p")`
  font-family: ${theme.primaryFontStack};
  font-size: 1.563rem;
  font-style: italic;
  margin: 0 auto;
  padding: 0.25em 1em 8px 0;
  text-align: left;
`;

export const TimeType = styled("div")`
  font-size: 1rem;
  font-weight: 800;
  color: #666;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.0262em;
  word-break: break-all;
`;

export const TimeValue = styled("div")`
  color: #666;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  word-break: break-all;
`;

export const IngredientHeader = styled("h3")`
  color: #824028;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1em;
  width: 93%;
  &:first-child {
    padding-top 0px;
  }
`;

export const InstructionHeader = styled("h4")`
  padding-top: 8px;
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 1.125rem;
  font-style: normal;
  letter-spacing: 0.0262em;
  text-transform: uppercase;
  color: #824028;
  padding-bottom: 0.262em;
  &:first-child {
    padding-top 0px;
    margin-top: 0px;
  }
`;

export const RecipeWrapper = styled("div")`
  margin-left: auto;
  margin-right: auto;
  padding: 0px 40px;
  width: 100%;
  max-width: 880px;
  margin-top: 2em;
  margin-bottom: 2em;
  ${media.oneColSteps`
    padding: 0px 50px;
    max-width: 900px;
  `}
`;
