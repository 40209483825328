import { slugify } from "@saffron/common";
import {
  CookbookAccessLevel,
  toChildAccess,
  useGetRecipeByIdWithCookbooksQuery,
  useMeQuery,
  ViewRecipeSearchQuery,
  viewRecipeSearchQuery
} from "@saffron/controllers";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { CookbookBreadcrumbs } from "../../../ui/CookbookBreadcrumbs";
import { Flex } from "../../../ui/Flex";
import { MainContent } from "../../../ui/Layout/MainContent";
import { SideBarLayout } from "../../../ui/Layout/SidebarLayout";
import Recipe from "../../../ui/Recipe";
import { RecipeWrapper } from "../../../ui/Recipe/components";
import { Sidebar } from "../../../ui/Sidebar";
import { usePrevious } from "../../../utils/usePrevious";
import { WarnIfNoRecipe } from "../../../utils/WarnIfNoRecipes";
import { RiconBar } from "../../ricons/RiconBar/index";
import { RecipeNoteSidebar } from "../../shared/RecipeNoteSidebar";
import { RecipeSearch } from "../../shared/RecipeSearch";
import { useFindRecipeState } from "./FindRecipeState";

type Props = RouteComponentProps<{}>;

const FindRecipeConnector: React.FC<Props> = ({ history, location }) => {
  const { data: meData } = useMeQuery();
  const [showNotes, setShowNotes] = useState(false);
  const {
    recipe,
    variables,
    onSearch,
    setCurrentRecipe
  } = useFindRecipeState();
  const fetchPolicy = recipe ? "cache-first" : "network-only";
  const { data } = useGetRecipeByIdWithCookbooksQuery({
    variables: {
      id: recipe?.id!
    },
    skip: !recipe,
    notifyOnNetworkStatusChange: true
  });

  const pKey = usePrevious(location.key);
  useEffect(() => {
    if (showNotes && pKey && location.key && pKey !== location.key) {
      setShowNotes(false);
    }
  }, [showNotes, pKey, location.key]);

  const recipeFromCache = data ? data.getRecipeById : null;

  const recipeAccessLevel = !recipeFromCache
    ? CookbookAccessLevel.Viewer
    : toChildAccess(
        recipeFromCache.owner,
        meData?.me.user?.id,
        recipeFromCache.cookbookSectionLookup?.[0]?.cookbook.accessLevel
      );
  const currentId = recipeFromCache?.id;
  const previousId = usePrevious(currentId);
  useEffect(() => {
    if (!currentId || !previousId) {
      return;
    }

    if (currentId !== previousId) {
      window.scrollTo(0, 0);
    }
  }, [previousId, currentId]);

  return (
    <WarnIfNoRecipe
      title="Search for recipes"
      message="Add recipes to your cookbooks to search and view results here."
    >
      <SideBarLayout>
        {showNotes && data?.getRecipeById ? (
          <RecipeNoteSidebar
            onRequestClose={() => setShowNotes(false)}
            recipe={data.getRecipeById}
          />
        ) : (
          <Sidebar>
            <RecipeSearch<
              ViewRecipeSearchQuery,
              ViewRecipeSearchQuery["recipeSearch"]["recipes"][0]
            >
              query={viewRecipeSearchQuery}
              onSearch={onSearch}
              onRecipeClick={setCurrentRecipe}
              sidebar
              initialState={{
                ...variables,
                fetchPolicy
              }}
            />
          </Sidebar>
        )}
        <MainContent>
          <Flex flex={1} flexDirection="column">
            {recipe && recipeFromCache ? (
              <React.Fragment>
                <RiconBar
                  recipeAccessLevel={recipeAccessLevel}
                  cookbookOptions={
                    recipeFromCache.cookbookSectionLookup &&
                    recipeFromCache.cookbookSectionLookup.length
                      ? {
                          onClone: r => {
                            history.push(
                              `/browse/${
                                recipeFromCache.cookbookSectionLookup![0]
                                  .cookbook.id
                              }/${
                                recipeFromCache.cookbookSectionLookup![0]
                                  .section.id
                              }/${r.id}`
                            );
                          },
                          cookbookSectionLookupInfo:
                            recipeFromCache.cookbookSectionLookup[0]
                        }
                      : undefined
                  }
                  recipe={recipeFromCache}
                  onEditRiconClick={() =>
                    recipeFromCache &&
                    history.push(
                      `/recipe/edit/${recipeFromCache.id}/${slugify(
                        recipeFromCache.name
                      )}`,
                      {
                        next: "/search"
                      }
                    )
                  }
                />
                {recipeFromCache && recipeFromCache.cookbookSectionLookup ? (
                  <RecipeWrapper
                    style={{ marginTop: "1.25em", marginBottom: 0 }}
                  >
                    <CookbookBreadcrumbs
                      cookbookSectionLookup={
                        recipeFromCache.cookbookSectionLookup
                      }
                      recipe={recipeFromCache}
                      recipeAccessLevel={recipeAccessLevel}
                    />
                  </RecipeWrapper>
                ) : null}
                <Flex flex={1}>
                  <Recipe
                    type={
                      meData?.me.user?.id === recipeFromCache.owner
                        ? "owner"
                        : "viewer"
                    }
                    notesOpen={showNotes}
                    onNotesClicked={() => setShowNotes(!showNotes)}
                    {...recipeFromCache}
                  />
                </Flex>
              </React.Fragment>
            ) : null}
          </Flex>
        </MainContent>
      </SideBarLayout>
    </WarnIfNoRecipe>
  );
};

export default FindRecipeConnector;
