import React from "react";
import styled from "styled-components";
import { theme } from "../../../../ui/theme";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../../menuPlanner/shared/ItemTypes";
import { RecipeNameFragment } from "@saffron/controllers";

interface Props {
  sectionId?: string;
  recipe: RecipeNameFragment;
  onRecipeClick: (r: RecipeNameFragment) => any;
  selected?: boolean;
  enableDrag?: boolean;
}

const Item = styled("div")<{ selected?: boolean }>`
  padding-right: 1.4em;
  padding-left: 1.4em;
  cursor: pointer;
  line-height: 18.9px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  word-break: break-word;
  font-size: 0.875rem;
  ${p =>
    p.selected
      ? `
            background-color: ${theme.selectedColor};
            color: #fff;
            `
      : `&:hover {
            background-color: ${theme.hoverColor};
          }`};
`;

export const RecipeItem: React.FC<Props> = ({
  enableDrag,
  recipe,
  selected,
  onRecipeClick,
  sectionId
}) => {
  const canDrag = !!sectionId && enableDrag;
  const [, drag] = useDrag({
    canDrag,
    item: {
      type: ItemTypes.RECIPE_IN_SECTION,
      recipe,
      sectionId
    }
  });

  return (
    <Item
      style={{ cursor: canDrag ? "grab" : undefined }}
      ref={drag}
      selected={selected}
      onClick={() => onRecipeClick(recipe)}
    >
      {recipe.name}
    </Item>
  );
};
