import { addPrettyFractionsToText } from "@saffron/common";
import {
  deleteMenuNoteMutationOptions,
  MenuPlannerQuery,
  MenuPlannerQueryVariables,
  updateMenuNoteMutationOptions,
  useDeleteMenuNoteMutation,
  useUpdateMenuNoteMutation
} from "@saffron/controllers";
import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";
import { DivButton } from "../../../../ui/DivButton";
import { ScalingBadge } from "../../../../ui/Recipe/ScalingBadge";
import { theme } from "../../../../ui/theme";
import { ItemTypes } from "../../shared/ItemTypes";

interface Props {
  menuItemOrNote: MenuPlannerQuery["menuPlanner"][0];
  onScaleClick: (menuItem: MenuPlannerQuery["menuPlanner"][0]) => void;
  onClick: () => void;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
}

const DivButtonWithHover = styled(DivButton)({
  ":hover": {
    backgroundColor: "#e6e6e6"
  }
});

export const DraggableMenuItem: React.FC<Props> = ({
  menuItemOrNote,
  onClick,
  onScaleClick,
  menuPlannerQueryVariables
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [{ isDragging }, drag] = useDrag({
    canDrag: menuItemOrNote.id !== "-1",
    item:
      menuItemOrNote.__typename === "MenuItem"
        ? {
            type: ItemTypes.MENU_ITEM,
            recipe: menuItemOrNote.recipe,
            menuItemId: menuItemOrNote.id
          }
        : {
            type: ItemTypes.MENU_NOTE,
            menuNoteId: menuItemOrNote.id
          },
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      };
    }
  });
  const [editState, setEditState] = useState({ active: false, text: "" });
  const [deleteNote] = useDeleteMenuNoteMutation();
  const [updateNote] = useUpdateMenuNoteMutation();
  const isNote = "text" in menuItemOrNote;

  const submit = () => {
    setEditState({ active: false, text: "" });
    if (!("text" in menuItemOrNote)) {
      return;
    }

    if (menuItemOrNote.text === editState.text) {
      return;
    }

    const trimmedText = editState.text.replace(/^\s+|\s+$/g, "");

    if (menuItemOrNote.text && !trimmedText) {
      deleteNote(
        deleteMenuNoteMutationOptions(
          {
            id: menuItemOrNote.id
          },
          menuPlannerQueryVariables
        )
      );
      return;
    }

    updateNote(
      updateMenuNoteMutationOptions(
        {
          id: menuItemOrNote.id,
          input: {
            text: trimmedText
          }
        },
        menuPlannerQueryVariables,
        undefined
      )
    );
  };

  if (editState.active) {
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <textarea
          style={{
            resize: "none",
            fontFamily: theme.uiFontStack,
            color: "#444",
            outline: "none",
            border: "none",
            fontSize: 14,
            background: "transparent",
            padding: 0,
            margin: "0 4px",
            width: "100%"
          }}
          onClick={e => e.stopPropagation()}
          autoFocus
          placeholder="note..."
          value={editState.text}
          onBlur={() => {
            submit();
          }}
          onKeyDown={e => {
            if (e.key === "Enter" && e.shiftKey === false) {
              e.preventDefault();
              submit();
              return;
            }
          }}
          ref={r => {
            if (r) {
              textAreaRef.current = r;
              textAreaRef.current.style.height = "";
              textAreaRef.current.style.height =
                textAreaRef.current.scrollHeight + "px";
            }
          }}
          onChange={e => {
            setEditState({ active: true, text: e.target.value });
            if (textAreaRef.current) {
              textAreaRef.current.style.height = "";
              textAreaRef.current.style.height =
                textAreaRef.current.scrollHeight + "px";
            }
          }}
        />
      </form>
    );
  }

  return (
    <DivButtonWithHover
      style={{
        cursor: isDragging ? "move" : "pointer",
        padding: "2px 4px",
        fontSize: "0.875rem",
        display: "flex",
        wordBreak: "break-word",
        marginBottom: 8,
        alignItems: "center",
        userSelect: "none",
        width: "100%"
      }}
      onClick={e => {
        e.stopPropagation();
        if ("text" in menuItemOrNote) {
          setEditState({ active: true, text: menuItemOrNote.text });
        } else {
          onClick();
        }
      }}
      ref={drag}
    >
      <div
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical"
        }}
      >
        <p
          style={{
            fontFamily: isNote ? theme.primaryFontStack : undefined,
            fontStyle: isNote ? "italic" : undefined,
            fontSize: isNote ? "0.8125rem" : undefined,
            whiteSpace: isNote ? "pre-line" : undefined,
            overflow: "hidden",
            margin: 0
          }}
        >
          {"text" in menuItemOrNote
            ? addPrettyFractionsToText(menuItemOrNote.text)
            : menuItemOrNote.recipe.name}
        </p>
      </div>
      {"scale" in menuItemOrNote && menuItemOrNote.scale !== 1 && (
        <div style={{ marginLeft: 4 }}>
          <ScalingBadge
            noAbsolutePos
            amount={menuItemOrNote.scale}
            onClick={e => {
              e.stopPropagation();
              onScaleClick(menuItemOrNote);
            }}
          />
        </div>
      )}
    </DivButtonWithHover>
  );
};
