import { useMenuPlannerRecipeSearchQuery } from "@saffron/controllers";
import React, { useState } from "react";
import { ScrollableBox } from "../../../ui/Box";
import { SmallButton } from "../../../ui/Button";
import { Flex } from "../../../ui/Flex";
import { SearchForm } from "../../shared/RecipeSearch/SearchForm";
import { DraggableRecipeCard } from "./DraggableRecipeCard";

interface Props {}

export const MenuPlannerSearch: React.FC<Props> = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const {
    data,
    refetch,
    loading,
    fetchMore,
    variables
  } = useMenuPlannerRecipeSearchQuery({
    skip: !searchQuery,
    variables: { searchQuery, offset: 0 }
  });
  const recipes = data && data.recipeSearch ? data.recipeSearch.recipes : [];

  return (
    <>
      <div style={{ marginBottom: "1em" }}>
        <SearchForm
          // smaller
          noPaddingBottom
          lessPaddingTop
          sidebar
          initialValues={{ searchQuery: variables?.searchQuery || "" }}
          placeholder="search..."
          search={x => {
            if (searchQuery === x.searchQuery) {
              refetch({
                searchQuery: searchQuery,
                offset: 0,
                recipesNotInCookbooks: false
              });
            } else {
              setSearchQuery(x.searchQuery);
            }
          }}
          allRecipesSearch={() => {
            if (searchQuery === "show all recipes") {
              refetch({
                searchQuery: searchQuery,
                offset: 0,
                recipesNotInCookbooks: false
              });
            } else {
              setSearchQuery("show all recipes");
            }
          }}
        />
      </div>
      <ScrollableBox flex={1}>
        <div style={{ padding: "47px 16px", paddingTop: 0 }}>
          {!loading && !recipes.length && searchQuery !== "" && (
            <div style={{ marginTop: "1em" }}>no results found</div>
          )}
          {recipes.map(r => (
            <DraggableRecipeCard key={r.id} r={r} />
          ))}
          {data && data.recipeSearch && data.recipeSearch.hasMoreRecipes && (
            <Flex>
              <SmallButton
                m="auto"
                mb="2em"
                onClick={() => {
                  if (!recipes.length || loading) {
                    return;
                  }

                  return fetchMore({
                    variables: {
                      ...variables,
                      offset: recipes.length
                    },
                    updateQuery: (
                      previousResult: any,
                      { fetchMoreResult }: any
                    ) => {
                      if (!fetchMoreResult) {
                        return previousResult;
                      }

                      return {
                        ...(previousResult as any),
                        recipeSearch: {
                          ...previousResult.recipeSearch,
                          recipes: [
                            ...(previousResult.recipeSearch.recipes || []),
                            ...(fetchMoreResult.recipeSearch.recipes || [])
                          ],
                          hasMoreRecipes:
                            fetchMoreResult.recipeSearch.hasMoreRecipes
                        }
                      };
                    }
                  });
                }}
              >
                Load More
              </SmallButton>
            </Flex>
          )}
        </div>
      </ScrollableBox>
    </>
  );
};
