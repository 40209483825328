import {
  deleteMenuNoteMutationOptions,
  MenuPlannerQueryVariables,
  StandardMenuNoteFragment,
  updateMenuNoteMutationOptions,
  useDeleteMenuNoteMutation,
  useUpdateMenuNoteMutation
} from "@saffron/controllers";
import React, { useState } from "react";
import { Flex } from "../../../ui/Flex";
import Icon from "../../../ui/Icon";
import { TextArea } from "../../../ui/Input";
import { Modal } from "../../../ui/Modal";

interface ViewNoteModalProps {
  onRequestClose: () => void;
  menuNote: StandardMenuNoteFragment;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
}

export const ViewNoteModal: React.FC<ViewNoteModalProps> = ({
  menuNote,
  onRequestClose,
  menuPlannerQueryVariables
}) => {
  const [text, setText] = useState(menuNote.text);
  const [deleteNote, { loading }] = useDeleteMenuNoteMutation();
  const [updateNote, { loading: l2 }] = useUpdateMenuNoteMutation();
  return (
    <Modal loading={l2} isOpen={!!menuNote} onRequestClose={onRequestClose}>
      <TextArea
        autoFocus
        onBlur={() => {
          updateNote(
            updateMenuNoteMutationOptions(
              {
                id: menuNote.id,
                input: {
                  text
                }
              },
              menuPlannerQueryVariables,
              undefined
            )
          );
        }}
        placeholder="note"
        maxRows={4}
        value={text}
        onChange={e => setText(e.target.value)}
      />
      {menuNote && menuNote.id !== "-1" ? (
        <Flex
          mt="4em"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Icon
            style={{ marginRight: 30 }}
            tooltipText="remove"
            name="circleMinus"
            fill="#666"
            template="recipe"
            onClick={async () => {
              if (loading) {
                return;
              }
              await deleteNote(
                deleteMenuNoteMutationOptions(
                  {
                    id: menuNote.id
                  },
                  menuPlannerQueryVariables
                )
              );
              onRequestClose();
            }}
          />
        </Flex>
      ) : null}
    </Modal>
  );
};
