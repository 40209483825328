import React, { useState, useRef } from "react";
import { SideBarLayout } from "../../../ui/Layout/SidebarLayout";
import { Sidebar } from "../../../ui/Sidebar";
import { MainContent } from "../../../ui/Layout/MainContent";
import {
  useGroceryList,
  GroceryListItemFragment,
  useDeleteCompletedGroceryItemsMutation,
  deleteCompletedGroceryItemsMutationOptions,
  useDeleteGroceryItemMutation,
  deleteGroceryItemMutationOptions,
  DeleteCompletedGroceryItemsMutationVariables,
  GroceryItemsQuery,
  GroceryItemsQueryVariables,
  formatGroceryItemsToCopy,
  useClearGroceryListMutation,
  clearGroceryListMutationOptions
} from "@saffron/controllers";
import { BoldSubhead } from "../../../ui/BoldSubhead";
import { RecipeRow } from "./RecipeRow";
import { AddGroceryItemInput } from "./AddGroceryItemInput";
import { GroceryCategoryHeader } from "./GroceryCategoryHeader";
import { KeywordGroup } from "./KeywordGroup";
import { GroceryItemRow } from "./GroceryItemRow";
import { EditGroceryItemModal } from "./EditGroceryItemModal";
import { Modal } from "../../../ui/Modal";
import Icon from "../../../ui/Icon";
import { theme } from "../../../ui/theme";
import { ChangeCatModal } from "./ChangeCatModal";
import { PrintableGroceryListClass } from "../PrintableGroceryList";
import ReactToPrint from "react-to-print";
import { copyToClipboard } from "../../../utils/copyToClipboard";
import { apolloClient } from "../../../apollo";
import { groceryItemsQuery } from "@saffron/controllers";
//
const GroceryListConnector = () => {
  const {
    recipes,
    recipeColorMap,
    recyclerListItems,
    onCategoryClick,
    onBundleToggle,
    loading
  } = useGroceryList();
  const printCompRef = useRef<any>();
  const [renderPrintComponent, setRenderPrintComponent] = useState(false);

  const [
    deleteCompletedGroceryItems,
    { loading: deleteCompletedGroceryItemsLoading }
  ] = useDeleteCompletedGroceryItemsMutation();
  const [
    clearGList,
    { loading: clearGListLoading }
  ] = useClearGroceryListMutation();
  const [
    deleteGroceryItem,
    { loading: deleteGroceryItemLoading }
  ] = useDeleteGroceryItemMutation();

  const [
    editModalItem,
    setEditModalItem
  ] = useState<GroceryListItemFragment | null>(null);
  const [
    changeCatModalItem,
    setChangeCatModalItem
  ] = useState<GroceryListItemFragment | null>(null);
  const [confirm, setConfirm] = useState<null | {
    type: "delete-item" | "clear-complete" | "clear-list";
    options: any;
    title: string;
    message: string;
  }>(null);

  const loadings = {
    "clear-list": clearGListLoading,
    "clear-complete": deleteCompletedGroceryItemsLoading || clearGListLoading,
    "delete-item": deleteGroceryItemLoading
  };
  const fns = {
    "clear-list": () => clearGList(clearGroceryListMutationOptions()),
    "clear-complete": () =>
      deleteCompletedGroceryItems(
        deleteCompletedGroceryItemsMutationOptions({})
      ),
    "delete-item": () =>
      deleteGroceryItem(deleteGroceryItemMutationOptions(confirm!.options))
  };

  const showRecipeText = !loading && !recipes.length;

  return (
    <SideBarLayout pattern={false}>
      <div style={{ display: "none" }}>
        <PrintableGroceryListClass
          show={renderPrintComponent}
          ref={printCompRef}
        />
      </div>
      <Sidebar noRecipeCount noBorderBottom>
        <BoldSubhead
          style={{
            paddingTop: 43,
            paddingLeft: 26,
            paddingRight: 26,
            margin: 0,
            paddingBottom: showRecipeText ? 8 : 32
          }}
        >
          Recipes
        </BoldSubhead>
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            paddingLeft: 26,
            paddingRight: 26
          }}
        >
          {showRecipeText ? (
            <div
              style={{
                fontFamily: theme.fonts.primary,
                fontSize: "1.0625rem",
                fontStyle: "italic"
              }}
            >
              When you add ingredients from a recipe, the recipe will show up
              here.
            </div>
          ) : null}
          {recipes.map(x => (
            <RecipeRow key={x.id} recipe={x} color={recipeColorMap[x.id]} />
          ))}
        </div>
      </Sidebar>
      <MainContent noPattern>
        <div
          style={{
            backgroundColor: "#fff",
            maxWidth: 700,
            width: "100%",
            minHeight: "100%",
            margin: "auto",
            borderLeft: "1px solid #E6E6E6",
            borderRight: "1px solid #E6E6E6"
          }}
        >
          <div
            style={{
              padding: 30,
              paddingTop: 18,
              display: "flex",
              flexDirection: "column",
              position: "sticky",
              backgroundColor: "#fff",
              top: 0
            }}
          >
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Icon
                style={{ marginRight: "2em" }}
                fill="#666"
                width={"1.4rem"}
                height={"1.4rem"}
                tooltipText="copy grocery items to clipboard"
                tooltipPosition="left"
                name="clipboard"
                onClick={async () => {
                  const response = await apolloClient.query<
                    GroceryItemsQuery,
                    GroceryItemsQueryVariables
                  >({ query: groceryItemsQuery, fetchPolicy: "cache-first" });
                  if (response.data) {
                    copyToClipboard(
                      formatGroceryItemsToCopy(response.data.groceryItems.items)
                    );
                  }
                }}
              />
              <ReactToPrint
                onBeforeGetContent={() => {
                  setRenderPrintComponent(true);
                  return Promise.resolve(0);
                }}
                trigger={() => (
                  <Icon
                    style={{ marginRight: "2em" }}
                    fill="#666"
                    width={"1.5rem"}
                    height={"1.5rem"}
                    tooltipText="print"
                    tooltipPosition="left"
                    name="print"
                  />
                )}
                content={() => printCompRef.current}
              />
              <Icon
                name="removeGroceryCart"
                viewBox="0 0 24 24"
                fill="#B56545"
                width="2.5rem"
                height="2.5rem"
                tooltipText="remove completed items"
                tooltipPosition="left"
                onClick={() => {
                  const oneComplete = recyclerListItems.some(
                    x => "complete" in x && x.complete
                  );
                  setConfirm({
                    title: "Confirm",
                    message: oneComplete
                      ? "Do you want to remove all completed grocery items?"
                      : "No items are crossed off, would you like to clear your entire list?",
                    type: oneComplete ? "clear-complete" : "clear-list",
                    options: {} as DeleteCompletedGroceryItemsMutationVariables
                  });
                }}
              />
            </div>
            <BoldSubhead
              style={{ fontSize: "1.875rem", marginBottom: 20, marginTop: -7 }}
            >
              Grocery List
            </BoldSubhead>
            <AddGroceryItemInput />
          </div>
          {!loading && recyclerListItems.length <= 1 ? (
            <div
              style={{
                fontFamily: theme.fonts.primary,
                padding: 30,
                paddingTop: 0,
                fontSize: "1.0625rem",
                fontStyle: "italic"
              }}
            >
              No items on your list yet.
            </div>
          ) : null}
          {recyclerListItems.map((item, i) => {
            // ignore first item
            if (!i) {
              return null;
            }

            if ("title" in item) {
              return (
                <GroceryCategoryHeader
                  onCategoryClick={onCategoryClick}
                  key={item.title}
                  {...item}
                />
              );
            }

            if ("groupKeyword" in item) {
              return (
                <KeywordGroup
                  key={item.groupKeyword}
                  {...item}
                  toggleOpen={onBundleToggle}
                />
              );
            }

            return (
              <GroceryItemRow
                color={
                  item.recipeId ? recipeColorMap[item.recipeId] : undefined
                }
                indent={item.inGroup}
                key={item.id}
                setConfirm={setConfirm}
                openEditModal={setEditModalItem}
                openChangeCatModal={setChangeCatModalItem}
                item={item}
              />
            );
          })}
        </div>
        <Modal
          title={confirm ? confirm.title : ""}
          isOpen={!!confirm}
          onRequestClose={() => setConfirm(null)}
          mainButtonText="OK"
          loading={confirm ? loadings[confirm.type] : false}
          secondaryButtonClick={() => setConfirm(null)}
          secondaryButtonText="CANCEL"
          mainButtonClick={() => {
            if (confirm) {
              (fns[confirm.type]() as any).then(() => {
                setConfirm(null);
              });
            }
          }}
        >
          {confirm ? confirm.message : ""}
        </Modal>
        <EditGroceryItemModal
          item={editModalItem}
          onRequestClose={() => setEditModalItem(null)}
        />
        <ChangeCatModal
          item={changeCatModalItem}
          onRequestClose={() => setChangeCatModalItem(null)}
        />
      </MainContent>
    </SideBarLayout>
  );
};

export default GroceryListConnector;
