import { sortBy } from "@saffron/common";
import {
  CookbookAccessLevel,
  CookbookSectionLookupInfo,
  JustSectionFragment,
  RecipeAccessLevel,
  useCookbooksQuery,
  useSectionsByCookbookIdQuery
} from "@saffron/controllers";
import React, { useEffect, useRef, useState } from "react";
import { FormField } from "../../ui/Forms/FormField";
import { Modal } from "../../ui/Modal";
import { Select } from "../../ui/Select";
import { theme } from "../../ui/theme";

interface Props {
  onRequestClosed: () => void;
  onSubmit: (x: CookbookSectionLookupInfo) => Promise<void>;
  title: string;
  buttonText: string;
  initialData: CookbookSectionLookupInfo;
  loading?: boolean;
  recipeAccessLevel: RecipeAccessLevel;
}

export const PickSectionModal: React.FC<Props> = ({
  recipeAccessLevel,
  onSubmit,
  onRequestClosed,
  title,
  buttonText,
  initialData,
  loading: submitLoading
}) => {
  const [cookbook, setCookbook] = useState(initialData.cookbook);
  const [section, setSection] = useState(initialData.section);
  const [errors, setErrors] = useState<{ cookbook?: string; section?: string }>(
    {}
  );
  const { data, loading } = useCookbooksQuery();
  const { data: sectionData } = useSectionsByCookbookIdQuery({
    skip: !cookbook,
    variables: {
      cookbookId: cookbook?.id
    }
  });
  const isCurrent = useRef(true);
  useEffect(
    () => () => {
      isCurrent.current = false;
    },
    []
  );

  let sections: JustSectionFragment[] = [];

  if (sectionData && sectionData.sectionsByCookbookId) {
    sections = sortBy(sectionData.sectionsByCookbookId, "position");
  }
  return (
    <>
      <Modal
        title={title}
        mainButtonText={buttonText}
        mainButtonClick={async () => {
          if (!cookbook) {
            setErrors({ cookbook: "required" });
            return;
          }
          if (!section) {
            setErrors({ section: "required" });
            return;
          }
          await onSubmit({ cookbook, section });
          if (isCurrent.current) {
            onRequestClosed();
          }
        }}
        isOpen
        onRequestClose={() => {
          onRequestClosed();
        }}
        loading={submitLoading}
      >
        <div style={{ marginTop: "1em" }}>
          {!data ||
          loading ||
          !data.cookbooks ||
          !data.cookbooks.length ? null : (
            <div>
              <FormField>
                {recipeAccessLevel === CookbookAccessLevel.Owner ? (
                  <div>
                    <Select
                      placeholder="Cookbook"
                      options={data.cookbooks
                        .filter(
                          x => x.accessLevel === CookbookAccessLevel.Owner
                        )
                        .map(cb => ({
                          key: cb.id,
                          value: cb.id,
                          text: cb.name
                        }))}
                      value={cookbook.id}
                      onChange={newValue => {
                        const cb = data.cookbooks.find(x => x.id === newValue);
                        if (cb) {
                          setCookbook(cb);
                        }
                      }}
                    />
                    {errors.cookbook && (
                      <div style={{ color: theme.colors.red }}>
                        {errors.cookbook}
                      </div>
                    )}
                  </div>
                ) : null}
              </FormField>
              {cookbook && (
                <>
                  <div>
                    <Select
                      placeholder="Section"
                      options={sections.map(s => ({
                        key: s.id,
                        value: s.id,
                        text: s.name
                      }))}
                      value={section.id}
                      onChange={newValue => {
                        const s = sections.find(x => x.id === newValue);
                        if (s) {
                          setSection(s);
                        }
                      }}
                    />
                    {errors.section && (
                      <div style={{ color: theme.colors.red }}>
                        {errors.section}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
