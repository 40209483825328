import React from "react";
import { Redirect } from "react-router";
import { BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY } from "./BrowseCookbookView";
import { makeBrowseUrl } from "./makeBrowseUrl";
import { IdStuff } from "./types";

interface BrowseFromLocalStorageProps {}

export const BrowseFromLocalStorage: React.FC<BrowseFromLocalStorageProps> = ({}) => {
  const [localStorageParams] = React.useState<IdStuff["ids"]>(() => {
    const sState = localStorage.getItem(
      BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY
    );

    if (!sState) {
      return {};
    }

    try {
      const state = JSON.parse(sState);
      if (typeof state === "string") {
        return {};
      }
      return state;
    } catch {
      return {};
    }
  });

  return <Redirect to={makeBrowseUrl(localStorageParams)} push={false} />;
};
