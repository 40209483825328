import React, { useRef } from "react";
import {
  GroceryListItemFragment,
  useUpdateGroceryItemMutation,
  updateGroceryItemMutationOptions,
  DeleteGroceryItemMutationVariables,
  addRecipeLinkIngredientsToGroceryListMutationOptions,
  useAddRecipeLinkIngredientsToGroceryListMutation
} from "@saffron/controllers";
import {
  addPrettyFractionsToText,
  itemUnitAmountToString
} from "@saffron/common";
import { DivButton } from "../../../ui/DivButton";
import TooltipTrigger from "react-popper-tooltip";
import { ListButton } from "../../../ui/ListButton";
import Icon from "../../../ui/Icon";
import { theme } from "../../../ui/theme";
import { notificationState } from "../../misc/notifications/NotificationState";

interface GroceryItemRowProps {
  item: GroceryListItemFragment;
  openEditModal: (item: GroceryListItemFragment) => void;
  openChangeCatModal: (item: GroceryListItemFragment) => void;
  color?: string;
  indent: boolean;
  setConfirm: React.Dispatch<
    React.SetStateAction<{
      type: "delete-item" | "clear-complete" | "clear-list";
      options: any;
      title: string;
      message: string;
    } | null>
  >;
}

const singleLineStyle = {
  // whiteSpace: "nowrap",
  // overflow: "hidden",
  // textOverflow: "ellipsis"
} as any;

export const GroceryItemRow: React.FC<GroceryItemRowProps> = React.memo(
  ({ item, color, indent, openEditModal, setConfirm, openChangeCatModal }) => {
    const tooltip = useRef<any>(undefined);
    const [updateGroceryItem, { loading }] = useUpdateGroceryItemMutation();
    const [
      addRecipeLinkIngredientsToGroceryList
    ] = useAddRecipeLinkIngredientsToGroceryListMutation();
    // const [
    //   addRecipeLinkIngredientsToGroceryList
    // ] = useAddRecipeLinkIngredientsToGroceryListMutation();

    const showName = !item.name || item.keyword !== item.name;

    let body = null;

    if (item.recipeLinkId) {
      body = (
        <div
          style={{
            ...singleLineStyle,
            textDecoration: item.complete ? "line-through" : undefined,
            color: item.complete ? "#999" : "#444"
          }}
        >
          {addPrettyFractionsToText(itemUnitAmountToString(item))} {item.name}
        </div>
      );
    } else if (item.complete) {
      body = (
        <>
          <div
            style={{
              ...singleLineStyle,
              textDecoration: "line-through",
              color: "#999"
            }}
          >
            {item.keyword || ""}{" "}
            {addPrettyFractionsToText(itemUnitAmountToString(item))}
          </div>
          {showName ? (
            <div
              style={{
                ...singleLineStyle,
                textDecoration: "line-through",
                color: "#999",
                fontSize: "1.125rem"
              }}
            >
              {item.name}
            </div>
          ) : null}
        </>
      );
    } else {
      body = (
        <React.Fragment>
          <div
            style={{
              ...singleLineStyle
            }}
          >
            <span
              style={{
                color: "#444",
                fontWeight: 600
              }}
            >{`${item.keyword || ""} `}</span>
            {addPrettyFractionsToText(itemUnitAmountToString(item))}
          </div>
          {showName ? (
            <div
              style={{
                ...singleLineStyle,
                fontSize: "1.125rem",
                color: "#999"
              }}
            >
              {item.name}
            </div>
          ) : null}
        </React.Fragment>
      );
    }

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          paddingLeft: indent ? 54 : 30
        }}
      >
        <div
          style={{
            marginTop: 30,
            width: 12,
            height: 12,
            borderRadius: "50%",
            backgroundColor: color || "#fff",
            marginRight: 10
          }}
        />
        <div
          style={{
            borderBottom: "1px solid #E6E6E6",
            display: "flex",
            paddingRight: 26,
            flex: 1
          }}
        >
          <DivButton
            disabled={loading}
            style={{
              flex: 1,
              padding: "21px 0",
              fontFamily: theme.fonts.ui,
              fontSize: "1.375rem",
              color: "#666666",
              display: "flex",
              flexDirection: "column"
            }}
            onClick={() => {
              updateGroceryItem(
                updateGroceryItemMutationOptions({
                  id: item.id,
                  input: { complete: !item.complete }
                })
              );
            }}
          >
            {body}
          </DivButton>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TooltipTrigger
              ref={tooltip}
              placement="bottom"
              trigger="click"
              tooltip={({ tooltipRef, getTooltipProps }) => {
                return (
                  <div
                    {...getTooltipProps({
                      ref: tooltipRef,
                      className: "tooltip-container",
                      style: {
                        width: 185,
                        paddingBottom: 5,
                        paddingTop: 5,
                        borderRadius: 4,
                        backgroundColor: "#fff",
                        boxShadow: "0 3px 12px rgba(27,31,35,.15)",
                        border: "1px solid rgba(27,31,35,.15)"
                      }
                    })}
                  >
                    {item.recipeLinkId ? (
                      <ListButton
                        onClick={() => {
                          if (tooltip.current) {
                            // @ts-ignore
                            tooltip.current.hideTooltip();
                          }

                          addRecipeLinkIngredientsToGroceryList(
                            addRecipeLinkIngredientsToGroceryListMutationOptions(
                              {
                                groceryItemId: item.id,
                                recipeId: item.recipeLinkId!,
                                amount: item.amount
                              }
                            )
                          ).then(response => {
                            if (response.data) {
                              const itemsAdded = (
                                response.data
                                  .addRecipeLinkIngredientsToGroceryList
                                  .groceryItems || []
                              ).length;
                              notificationState.send({
                                variant: "success",
                                text: `successfully added ${itemsAdded} ingredients`
                              });
                            }
                          });
                        }}
                      >
                        Add ingredients to list
                      </ListButton>
                    ) : (
                      <>
                        <ListButton
                          onClick={() => {
                            if (tooltip.current) {
                              // @ts-ignore
                              tooltip.current.hideTooltip();
                            }

                            openEditModal(item);
                          }}
                        >
                          Edit
                        </ListButton>
                        <ListButton
                          onClick={() => {
                            if (tooltip.current) {
                              // @ts-ignore
                              tooltip.current.hideTooltip();
                            }

                            openChangeCatModal(item);
                          }}
                        >
                          Change Category
                        </ListButton>
                      </>
                    )}
                    <ListButton
                      onClick={() => {
                        if (tooltip.current) {
                          // @ts-ignore
                          tooltip.current.hideTooltip();
                        }
                        setConfirm({
                          title: "Confirm",
                          message:
                            "Are you sure you want to delete this grocery item?",
                          type: "delete-item",
                          options: {
                            id: item.id
                          } as DeleteGroceryItemMutationVariables
                        });
                      }}
                    >
                      Delete
                    </ListButton>
                  </div>
                );
              }}
            >
              {({ getTriggerProps, triggerRef }) => (
                <div
                  {...(!item.complete
                    ? getTriggerProps({
                        ref: triggerRef,
                        className: "trigger",
                        style: {
                          marginTop: 2.5,
                          padding: "0px 8px"
                        }
                      })
                    : {
                        style: {
                          marginTop: 2.5,
                          padding: "0px 8px"
                        }
                      })}
                >
                  <Icon
                    name="dots"
                    width="22px"
                    height="22px"
                    fill={item.complete ? "#fff" : "#ccc"}
                  />
                </div>
              )}
            </TooltipTrigger>
          </div>
        </div>
      </div>
    );
  }
);
