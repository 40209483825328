import React from "react";
import { Pattern } from "../Pattern";

interface Props {
  pattern?: boolean;
}

export const SideBarLayout: React.FC<Props> = ({
  children,
  pattern = true
}) => {
  return (
    <>
      {children}
      {pattern && <Pattern />}
    </>
  );
};
