import {
  addRecipeToSectionMutationOptions,
  bulkMoveRecipeSectionsMutationOptions,
  CookbookAccessLevel,
  CookbookSectionLookupInfo,
  RecipeAccessLevel,
  RecipeNameFragment,
  removeRecipeFromSectionMutationOptions,
  useAddRecipeToSectionMutation,
  useBulkMoveRecipeSectionsMutation,
  useRemoveRecipeFromSectionMutation,
  ViewRecipeSearchQuery
} from "@saffron/controllers";
import React, { useState } from "react";
import styled from "styled-components";
import { notificationState } from "../modules/misc/notifications/NotificationState";
import { PickSectionModal } from "../modules/shared/PickSectionModal";
import { DivButton } from "./DivButton";
import Icon from "./Icon";
import { theme } from "./theme";

interface Props {
  recipeAccessLevel: RecipeAccessLevel;
  recipe: RecipeNameFragment;
  cookbookSectionLookup: NonNullable<
    ViewRecipeSearchQuery["recipeSearch"]["recipes"][0]["cookbookSectionLookup"]
  >;
}

const Container = styled.div({
  backgroundColor: "#e9e7df",
  color: "#4d4d4d",
  fontFamily: theme.fonts.ui,
  "& > a:hover": {
    backgroundColor: "#DFDBD3"
  }
});

export const CookbookBreadcrumbs: React.FC<Props> = ({
  cookbookSectionLookup,
  recipe,
  recipeAccessLevel
}) => {
  const [
    moveRecipes,
    { loading: loadingMoveRecipe }
  ] = useBulkMoveRecipeSectionsMutation();
  const [moveInfo, setMoveInfo] = useState<CookbookSectionLookupInfo | null>(
    null
  );
  const [
    removeRecipeFromSection,
    { loading }
  ] = useRemoveRecipeFromSectionMutation();
  const [
    addRecipeToSection,
    { loading: loadingAddRecipeToSection }
  ] = useAddRecipeToSectionMutation();
  const [addOpen, setAddOpen] = useState(false);
  const canDelete = cookbookSectionLookup.length > 1;
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {cookbookSectionLookup.map(({ cookbook, section }) => {
        const showRightIcon =
          canDelete && cookbook.accessLevel !== CookbookAccessLevel.Viewer;
        return (
          <Container
            key={cookbook.id + section.id}
            style={{
              fontSize: "0.75rem",
              lineHeight: "0.9375rem",
              marginBottom: 8,
              marginRight: 16,
              display: "flex"
            }}
          >
            <DivButton
              style={{
                display: "block",
                padding: "4px 8px",
                paddingRight: showRightIcon ? 3 : 8,
                cursor:
                  cookbook.accessLevel === CookbookAccessLevel.Viewer
                    ? "default"
                    : undefined
              }}
              disabled={cookbook.accessLevel === CookbookAccessLevel.Viewer}
              onClick={() => {
                setMoveInfo({ cookbook, section });
              }}
            >
              <span>{cookbook.name}</span>
              <span style={{ color: "#737373" }}> / {section.name}</span>
            </DivButton>
            {showRightIcon ? (
              <DivButton
                onClick={() => {
                  removeRecipeFromSection(
                    removeRecipeFromSectionMutationOptions({
                      options: {
                        recipeId: recipe.id,
                        sectionIdToRemove: section.id
                      }
                    })
                  );
                }}
                disabled={loading}
                style={{ paddingRight: 3 }}
              >
                <Icon
                  style={{ marginTop: 1 }}
                  name={"x"}
                  height={15}
                  width={15}
                  fill="#7A766F"
                />
              </DivButton>
            ) : null}
          </Container>
        );
      })}
      {recipeAccessLevel !== CookbookAccessLevel.Viewer ? (
        <DivButton
          onClick={() => setAddOpen(true)}
          style={{ marginBottom: 8, marginLeft: -7 }}
        >
          <Icon name="plus" fill="#7A766F" />
        </DivButton>
      ) : null}
      {addOpen ? (
        <PickSectionModal
          recipeAccessLevel={recipeAccessLevel}
          title="Add Recipe to Additional Cookbook"
          buttonText="ADD"
          loading={loadingAddRecipeToSection}
          initialData={cookbookSectionLookup[0]}
          onRequestClosed={() => setAddOpen(false)}
          onSubmit={async ({ cookbook, section }) => {
            await addRecipeToSection(
              addRecipeToSectionMutationOptions(
                {
                  recipeId: recipe.id,
                  sectionId: section.id
                },
                recipe,
                {
                  __typename: "CookbookSectionLookup",
                  cookbook,
                  section
                }
              )
            );
            notificationState.send({
              text: "successfully added",
              variant: "success"
            });
          }}
        />
      ) : null}
      {moveInfo ? (
        <PickSectionModal
          recipeAccessLevel={recipeAccessLevel}
          title="Move Recipe"
          buttonText="MOVE"
          initialData={moveInfo}
          loading={loadingMoveRecipe}
          onRequestClosed={() => setMoveInfo(null)}
          onSubmit={async data => {
            await moveRecipes(
              bulkMoveRecipeSectionsMutationOptions(
                {
                  options: {
                    recipeIds: [recipe.id],
                    targetSectionId: data.section.id,
                    sectionIdToRemove: moveInfo.section.id
                  }
                },
                moveInfo.section.id,
                [
                  {
                    __typename: "Recipe",
                    id: recipe.id,
                    name: recipe.name,
                    pictureUrl: recipe.pictureUrl,
                    smallPictureUrl: recipe.smallPictureUrl,
                    source: recipe.source,
                    createdAt: recipe.createdAt,
                    rating: recipe.rating
                  }
                ],
                {
                  __typename: "CookbookSectionLookup",
                  ...data
                }
              )
            );
            notificationState.send({
              text: "successfully moved",
              variant: "success"
            });
          }}
        />
      ) : null}
    </div>
  );
};
