import React from "react";
import { DivButton } from "../../../ui/DivButton";
import Icon from "../../../ui/Icon";
import { theme } from "../../../ui/theme";
import {
  useCompleteGroceryItemByKeywordMutation,
  completeGroceryItemByKeywordMutationOptions
} from "@saffron/controllers";

interface KeywordGroupProps {
  complete: boolean;
  open: boolean;
  groupKeyword: string;
  toggleOpen: (keyword: string) => void;
}

export const KeywordGroup: React.FC<KeywordGroupProps> = React.memo(
  ({ groupKeyword, toggleOpen, open, complete }) => {
    const [
      completeGroceryItemByKeyword
    ] = useCompleteGroceryItemByKeywordMutation();

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          paddingLeft: 52
        }}
      >
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid #E6E6E6",
            height: "100%",
            flex: 1,
            paddingRight: 14
          }}
        >
          <Icon
            style={{ marginRight: 6, marginBottom: 1 }}
            fill="#444"
            height="22px"
            width="22px"
            name="groceryBasket"
          />
          <DivButton
            style={{
              textDecoration: complete ? "line-through" : undefined,
              color: complete ? "#999" : "#444444",
              fontFamily: theme.fonts.ui,
              fontWeight: 600,
              padding: "30px 0px",
              fontSize: "1.375rem",
              lineHeight: "1.875rem",
              flex: 1
            }}
            onClick={() => {
              completeGroceryItemByKeyword(
                completeGroceryItemByKeywordMutationOptions({
                  complete: !complete,
                  keyword: groupKeyword
                })
              );
            }}
          >
            {groupKeyword}
          </DivButton>
          <DivButton
            style={{
              padding: "30px 0px",
              paddingLeft: 16,
              paddingRight: 16
            }}
            onClick={() => toggleOpen(groupKeyword)}
          >
            <Icon template="gl" name={open ? "upArrow" : "downArrow"} />
          </DivButton>
        </div>
      </div>
    );
  }
);
