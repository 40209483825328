import {
  CookbookAccessLevel,
  CookbooksQuery,
  SectionsByCookbookIdQuery,
  useCookbooksQuery,
  useGetMyApiKeyQuery,
  useSectionsByCookbookIdQuery
} from "@saffron/controllers";
import React, { useState } from "react";
import { LabelText } from "../../ui/Input";
import { CenterLayout } from "../../ui/Layout/CenterLayout";
import { PrettyLink } from "../../ui/PrettyLink";
import { Select } from "../../ui/Select";
import { theme } from "../../ui/theme";
import { AccountWrapper } from "./AccountStatus";

interface BookmarkButtonCreatorProps {}

export const BookmarkButtonCreator: React.FC<BookmarkButtonCreatorProps> = ({}) => {
  const { data: keyData } = useGetMyApiKeyQuery();
  const [cookbook, setCookbook] = useState<
    CookbooksQuery["cookbooks"][0] | null
  >(null);
  const [section, setSection] = useState<
    SectionsByCookbookIdQuery["sectionsByCookbookId"][0] | null
  >(null);
  const { data } = useCookbooksQuery({
    onCompleted: d => {
      if (d.cookbooks.length && !cookbook) {
        setCookbook(d.cookbooks[0]);
      }
    }
  });
  const { data: sectionData } = useSectionsByCookbookIdQuery({
    skip: !cookbook,
    variables: {
      cookbookId: cookbook?.id!
    },
    onCompleted: d => {
      if (d.sectionsByCookbookId.length && !section) {
        setSection(d.sectionsByCookbookId[0]);
      }
    }
  });

  return (
    <CenterLayout
      patternImage="gold"
      backgroundImage="creme"
      innerPadding="0px"
      style={{
        maxHeight: "100%",
        paddingTop: "3em",
        paddingBottom: "3em"
      }}
    >
      <AccountWrapper>
        <div
          style={{
            marginBottom: 20
          }}
        >
          <div
            style={{
              fontFamily: theme.primaryFontStack,
              fontSize: "1.5625rem"
            }}
          >
            Bookmark Button
          </div>
          <div style={{ marginTop: "1em" }}>
            Select the cookbook you'd like the recipes to be imported into when
            you press the bookmark button.
          </div>
          <LabelText style={{ marginTop: "1em", marginBottom: 6 }}>
            cookbook
          </LabelText>
          <Select
            placeholder="Cookbook"
            options={
              data?.cookbooks
                .filter(x => x.accessLevel !== CookbookAccessLevel.Viewer)
                .map(cb => ({
                  key: cb.id,
                  value: cb.id,
                  text: cb.name
                })) || []
            }
            value={cookbook?.id}
            onChange={newValue => {
              const cb = data?.cookbooks.find(x => x.id === newValue);
              if (cb) {
                setCookbook(cb);
                setSection(null);
              }
            }}
          />
          {!cookbook ? null : (
            <>
              <LabelText style={{ marginTop: "1em", marginBottom: 6 }}>
                section
              </LabelText>
              <Select
                placeholder="Section"
                options={
                  sectionData?.sectionsByCookbookId.map(s => ({
                    key: s.id,
                    value: s.id,
                    text: s.name
                  })) || []
                }
                value={section?.id}
                onChange={newValue => {
                  const s = sectionData?.sectionsByCookbookId.find(
                    x => x.id === newValue
                  );
                  if (s) {
                    setSection(s);
                  }
                }}
              />
            </>
          )}
        </div>
        <PrettyLink
          href={`javascript:(function(){ window.open("${
            process.env.NODE_ENV === "production"
              ? "https://prod.mysaffronapp.com"
              : "http://192.168.1.165:8080"
          }/import?sectionId=${section?.id}&apiKey=${
            keyData?.getMyApiKey
          }&url="+encodeURIComponent(location.href)); })();`}
        >
          drag this link into your bookmark bar
        </PrettyLink>
      </AccountWrapper>
    </CenterLayout>
  );
};
