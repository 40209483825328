import {
  MenuDay,
  MenuSectionColor,
  MenuPlannerQuery,
  MenuPlannerQueryVariables
} from "@saffron/controllers";
import React from "react";
import styled from "styled-components";
import { Flex } from "../../../../ui/Flex";
import { DraggableMenuItem } from "./DraggableMenuItem";
import { MenuSectionDragSource } from "./MenuSectionDragSource";
import { theme } from "../../../../ui/theme";
import dayjs from "dayjs";
import { TopOfDay } from "./TopOfDay";

interface Props {
  menuSections: MenuSectionColor[];
  menuDays: MenuDay[];
  onMenuItemClick: (menuItem: MenuPlannerQuery["menuPlanner"][0]) => void;
  onScaleClick: (menuItem: MenuPlannerQuery["menuPlanner"][0]) => void;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
  onOpenNewNoteModal: (dateKey: string) => void;
}

const Grid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  // overflowX: "auto",
  fontFamily: theme.fonts.ui
});

export const WeekView: React.FC<Props> = ({
  menuDays,
  menuSections,
  onMenuItemClick,
  menuPlannerQueryVariables,
  onScaleClick,
  onOpenNewNoteModal
}) => {
  const todayDate = dayjs();
  return (
    <Grid>
      {menuDays.map((md, i) => {
        const gridColumn = i + 1;
        const gridRow = 1;
        return (
          <React.Fragment key={md.dtKey}>
            <Flex
              border="1px solid #DDE2E3;"
              p="5px 10px"
              fontFamily="ui"
              style={{
                backgroundColor: "#fff",
                minHeight: 370,
                gridColumn,
                gridRow: `row ${gridRow} / span ${menuSections.length}`,
                marginBottom: 20,
                borderLeft: !i ? undefined : "none"
              }}
            >
              <TopOfDay
                md={md}
                activeDay={todayDate.isSame(md.dt, "day")}
                onOpenNewNoteModal={onOpenNewNoteModal}
              />
            </Flex>
            {menuSections.map((ms, k) => {
              const menuItemsOrNotes = md.menuItemOrNoteSectionMap[ms.id] || [];
              const lastSection = k + 1 === menuSections.length;
              return (
                <MenuSectionDragSource
                  key={`${md.dtKey}-${ms.id}-sn-week-view`}
                  k={k}
                  gridColumn={gridColumn}
                  gridRow={gridRow}
                  lastSection={lastSection}
                  menuItems={menuItemsOrNotes}
                  ms={ms}
                  md={md}
                  menuPlannerQueryVariables={menuPlannerQueryVariables}
                >
                  <strong
                    style={{
                      padding: "0 4px",
                      color: ms.color,
                      fontSize: "0.75rem"
                    }}
                  >
                    {ms.name}
                  </strong>
                  {menuItemsOrNotes.map(mi => (
                    <DraggableMenuItem
                      key={mi.id}
                      menuItemOrNote={mi}
                      onClick={() => onMenuItemClick(mi)}
                      onScaleClick={onScaleClick}
                      menuPlannerQueryVariables={menuPlannerQueryVariables}
                    />
                  ))}
                </MenuSectionDragSource>
              );
            })}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
