import { slugify } from "@saffron/common";
import {
  toChildAccess,
  useGetRecipeByIdWithCookbooksQuery,
  useMeQuery
} from "@saffron/controllers";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Box } from "../../ui/Box";
import { CookbookBreadcrumbs } from "../../ui/CookbookBreadcrumbs";
import { MainContent } from "../../ui/Layout/MainContent";
import { SideBarLayout } from "../../ui/Layout/SidebarLayout";
import { RecipeWrapper } from "../../ui/Recipe/components";
import Recipe from "../../ui/Recipe/index";
import { patternWidthValue } from "../../ui/theme";
import { isUuid } from "../../utils/isUuid";
import { pushLocation } from "../../utils/pushLocation";
import NotFound from "../misc/error/NotFound";
import { RiconBar } from "../ricons/RiconBar/index";
import { scaleIgContainer } from "../ricons/ScaleIgContainer";
import { RecipeNoteSidebar } from "../shared/RecipeNoteSidebar";

type Props = RouteComponentProps<{ id: string; slug: string }>;

const ViewRecipe: React.FC<Props> = ({
  match: {
    params: { slug, id }
  },
  location: { search },
  history
}) => {
  const { data: meData } = useMeQuery();
  const [showNotes, setShowNotes] = useState(false);
  const validId = isUuid(id);
  const { data, loading } = useGetRecipeByIdWithCookbooksQuery({
    skip: !validId,
    variables: { id },
    onCompleted: d => {
      if (d?.getRecipeById?.notes) {
        setShowNotes(true);
      }
    }
  });

  useEffect(() => {
    if (search && validId) {
      const parsed = queryString.parse(search);
      if (parsed.scale && typeof parsed.scale === "string") {
        const n = parseFloat(parsed.scale);
        if (!isNaN(n)) {
          scaleIgContainer.setAmount(id, n);
        }
      }
    }
  }, [search, validId, id]);

  if (!validId) {
    return <NotFound />;
  }

  if (loading) {
    return null;
  }

  if (!data?.getRecipeById) {
    return <NotFound />;
  }

  const { getRecipeById } = data;

  if (getRecipeById) {
    const correctSlug = slugify(getRecipeById.name);
    if (slug !== correctSlug) {
      return <Redirect to={`/recipe/${getRecipeById.id}/${correctSlug}`} />;
    }
  }

  const recipeAccessLevel = toChildAccess(
    getRecipeById.id,
    meData?.me.user?.id,
    getRecipeById.cookbookSectionLookup?.[0]?.cookbook.accessLevel
  );

  const inside = (
    <>
      <div style={{ width: "100%", height: "1em" }} />
      <RiconBar
        recipeAccessLevel={recipeAccessLevel}
        recipe={getRecipeById}
        cookbookOptions={
          getRecipeById.cookbookSectionLookup &&
          getRecipeById.cookbookSectionLookup.length
            ? {
                cookbookSectionLookupInfo:
                  getRecipeById.cookbookSectionLookup[0],
                onClone: r => {
                  history.push(
                    `/browse/${
                      getRecipeById.cookbookSectionLookup![0].cookbook.id
                    }/${getRecipeById.cookbookSectionLookup![0].section.id}/${
                      r.id
                    }`
                  );
                }
              }
            : undefined
        }
        onDeleteRiconClick={() => pushLocation("/go/browse")}
        onEditRiconClick={() => {
          history.push(`/recipe/edit/${id}/${slug}`, {
            next: `/recipe/${id}/${slug}`
          });
        }}
      />
      {getRecipeById && getRecipeById.cookbookSectionLookup ? (
        <RecipeWrapper style={{ marginTop: "1.25em", marginBottom: 0 }}>
          <CookbookBreadcrumbs
            cookbookSectionLookup={getRecipeById.cookbookSectionLookup}
            recipe={getRecipeById}
            recipeAccessLevel={recipeAccessLevel}
          />
        </RecipeWrapper>
      ) : null}
      <Recipe
        type={meData?.me.user?.id === getRecipeById.owner ? "owner" : "viewer"}
        notesOpen={showNotes}
        onNotesClicked={() => setShowNotes(!showNotes)}
        {...getRecipeById}
      />
    </>
  );

  return (
    <SideBarLayout>
      {showNotes ? (
        <RecipeNoteSidebar
          onRequestClose={() => setShowNotes(false)}
          recipe={getRecipeById}
        />
      ) : null}
      {showNotes ? (
        <MainContent>{inside}</MainContent>
      ) : (
        <Box flex={1} mb="2em" pl={patternWidthValue}>
          {inside}
        </Box>
      )}
    </SideBarLayout>
  );
};

export default ViewRecipe;
