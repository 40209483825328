import { slugify } from "@saffron/common";
import { IdStuff } from "./types";

export const makeBrowseUrl = (d: IdStuff["ids"]) => {
  const path = ["/browse"];
  if (d.currentCookbookId) {
    path.push(d.currentCookbookId);
    if (d.currentSectionId) {
      path.push(d.currentSectionId);
      if (d.currentRecipeId) {
        path.push(d.currentRecipeId);
        if (d.slug) {
          path.push(slugify(d.slug));
        }
      }
    }
  }
  return path.join("/");
};
